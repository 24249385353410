const config = {
  /** for identifee dev */
  API_URL: process.env.REACT_APP_API_URL,
  DASHBOARD_API_URL: process.env.REACT_APP_DASHBOARD_API_URL,
  DASHBOARD_URL: process.env.REACT_APP_DASHBOARD_URL,

  APP_IMAGE_URI: process.env.REACT_APP_APP_IMAGE_URI,
  APP_FILE_URI: process.env.REACT_APP_APP_FILE_URI,
};

export default config;
