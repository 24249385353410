// @flow
import { APICore } from "./apiCore";

const api = new APICore();

// account
function invoicesList(params: any): any {
  const baseUrl = "/invoices";
  return api.getEmpty(`${baseUrl}`, params);
}

function invoicesListAll(): any {
  const baseUrl = "/invoices/all";
  //console.log("------------->"+baseUrl);
  return api.get(`${baseUrl}`, {});
}

function invoicesFetch(invoice_id) {
  //console.log("safasdfdasf"+ JSON.stringify(invoice_id.invoice_id));
  const baseUrl = "/invoices/" + invoice_id.invoice_id;
  return api.get(`${baseUrl}`, {});
}

function markInvoiceViewed(params: any): any {
  const baseUrl = "/stats/invoices/mark-viewed";
  return api.createWithFile(`${baseUrl}`, params);
}

function invoiceDueAmmount(params: any): any {
  const baseUrl = "/invoices/total-amount-due";
  return api.createWithFile(`${baseUrl}`, params);
}

function payInvoiceWithClientSource(params: any): any {
  const baseUrl = "/invoices/pay-with-client-source";
  return api.createWithFile(`${baseUrl}`, params);
}

function payOfflineInvoice(params: any): any {
  const baseUrl = "/invoices/save-promise-to-pay";
  return api.createWithFile(`${baseUrl}`, params);
}

function payInvoiceStripe(params: any) {
  const baseUrl = "/payments/stripe/pay-invoice";
  console.log("paramas: " + JSON.stringify(params));
  return api.createWithFile(`${baseUrl}`, params);
}

function payInvoiceBrainTree(params: any): any {
  const baseUrl = "/payments/braintree/pay-invoice";
  return api.createWithFile(`${baseUrl}`, params);
}
function payInvoiceAuthorize(params: any): any {
  const baseUrl = "/payments/authorizenet/pay-invoice";
  return api.createWithFile(`${baseUrl}`, params);
}
function payInvoiceCybersource(params: any): any {
  const baseUrl = "/payments/cybersource/pay-invoice";
  return api.createWithFile(`${baseUrl}`, params);
}

function payInvoicePaypal(params: any): any {
  const baseUrl = "/payments/paypal/pay-invoice";
  return api.createWithFile(`${baseUrl}`, params);
}

function payInvoiceOpenNode(params: any): any {
  const baseUrl = "/payments/opennode/pay-invoice";
  return api.createWithFile(`${baseUrl}`, params);
}

function payInvoiceNmi(params: any): any {
  const baseUrl = "/payments/nmi/pay-invoice";
  return api.createWithFile(`${baseUrl}`, params);
}

export {
  payInvoiceStripe,
  payInvoiceBrainTree,
  payInvoiceAuthorize,
  payInvoiceCybersource,
  payInvoicePaypal,
  invoicesList,
  invoicesListAll,
  invoicesFetch,
  invoiceDueAmmount,
  payInvoiceWithClientSource,
  payOfflineInvoice,
  markInvoiceViewed,
  payInvoiceOpenNode,
  payInvoiceNmi,
};
