// @flow
import { EstimateActionTypes } from "./constants";

type EstimateAction = { type: string, payload: {} | string };

// common success
export const estimateApiResponseSuccess = (
  actionType: string,
  data: any
): EstimateAction => ({
  type: EstimateActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});
// common error
export const estimateApiResponseError = (
  actionType: string,
  error: string
): EstimateAction => ({
  type: EstimateActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const estimateList = (): EstimateAction => ({
  type: EstimateActionTypes.ESTIMATES,
  payload: {},
});

export const estimateListAll = (): EstimateAction => ({
  type: EstimateActionTypes.ESTIMATES_ALL,
  payload: {},
});

export const estimateFetch = (estimate_id): EstimateAction => ({
  type: EstimateActionTypes.ESTIMATES_FETCH,
  payload: { estimate_id },
});

export const estimateAccept = (id): EstimateAction => ({
  type: EstimateActionTypes.ACCEPT_ESTIMATE,
  payload: { id },
});

export const resetEstimate = (): EstimateAction => ({
  type: EstimateActionTypes.RESET,
  payload: {},
});
