export const EstimateActionTypes = {
  API_RESPONSE_SUCCESS: "@@estimate/API_RESPONSE_SUCCESS",
  API_RESPONSE_ERROR: "@@estimate/API_RESPONSE_ERROR",

  ESTIMATES: "@@estimate/ESTIMATES",
  ESTIMATES_ALL: "@@estimate/ESTIMATES_ALL",
  ESTIMATES_FETCH: "@@estimate/ESTIMATES_FETCH",
  ACCEPT_ESTIMATE: "@@estimate/ACCEPT_ESTIMATE",

  RESET: "@@estimate/RESET",
};
