import React, { useEffect, useState } from "react";
import { Route, Redirect } from "react-router-dom";

import { APICore } from "../helpers/api/apiCore";
import { useDispatch } from "react-redux";
import { account } from "../redux/actions";

/**
 * Private Route forces the authorization before the route can be accessed
 * @param {*} param0
 * @returns
 */
const PrivateRoute = ({ component: Component, roles, ...rest }) => {
  const [, setHost] = useState();
  const api = new APICore();
  const dispatch = useDispatch();
  useEffect(() => {
    // resetAuth()
    const domainName = window.location.hostname; // 3f9603b6c2bf
    const hosti = domainName.split(".")[0];

    // console.log(hosti); // "reactgo.com"

    setHost(hosti);

    if (hosti) {
      dispatch(account(hosti));
    }
  }, []);
  return (
    <Route
      {...rest}
      render={(props) => {
        if (api.isUserAuthenticated() === false) {
          // not logged in so redirect to login page with the return url
          return (
            <Redirect
              to={{ pathname: "/login", state: { from: props.location } }}
            />
          );
        }

        return <Component {...props} />;
      }}
    />
  );
};

export default PrivateRoute;
