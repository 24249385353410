// @flow
import React, {
  Fragment,
  Suspense,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useSelector, useDispatch } from "react-redux";
import { Redirect } from "react-router-dom";

// actions
import { changeSidebarType, changeSidebarTheme } from "../redux/actions";

import * as layoutConstants from "../constants/layout";

import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import TopHeaderSearch from "./tailwind-layout/TopHeaderSearch";

const LeftSidebar = React.lazy(() => import("./LeftSidebar"));

const loading = () => <div className=""></div>;

type VerticalLayoutProps = {
  children?: any,
};

type VerticalLayoutState = {
  isMenuOpened?: boolean,
};

const VerticalLayout = ({
  children,
}: VerticalLayoutProps): React$Element<any> => {
  const dispatch = useDispatch();

  const { leftSideBarTheme, leftSideBarType } = useSelector((state) => ({
    layoutWidth: state.Layout.layoutWidth,
    leftSideBarTheme: state.Layout.leftSideBarTheme,
    leftSideBarType: state.Layout.leftSideBarType,
    showRightSidebar: state.Layout.showRightSidebar,
  }));

  const { errorIs } = useSelector((state) => ({
    errorIs: state.Auth.accountError,
  }));

  const updateDimensions = useCallback(() => {
    // activate the condensed sidebar if smaller devices like ipad or tablet
    if (window.innerWidth >= 768 && window.innerWidth <= 1028) {
      dispatch(changeSidebarType(layoutConstants.LEFT_SIDEBAR_TYPE_CONDENSED));
    } else if (window.innerWidth > 1028) {
      dispatch(changeSidebarType(layoutConstants.LEFT_SIDEBAR_TYPE_FIXED));
    }
  }, [dispatch]);

  useEffect(() => {
    dispatch(changeSidebarTheme(layoutConstants.LEFT_SIDEBAR_THEME_LIGHT));

    // activate the condensed sidebar if smaller devices like ipad or tablet
    if (window.innerWidth >= 768 && window.innerWidth <= 1028) {
      dispatch(changeSidebarType(layoutConstants.LEFT_SIDEBAR_TYPE_CONDENSED));
    }

    window.addEventListener("resize", updateDimensions);

    return () => {
      window.removeEventListener("resize", updateDimensions);
    };
    //  alert(JSON.stringify(children));
  }, [dispatch, updateDimensions]);
  useEffect(() => {
    //  alert(JSON.stringify(children));
    //document.title = props.title
  }, [children]);

  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [menuRef, setMenuRef] = useState("");
  const isCondensed =
    leftSideBarType === layoutConstants.LEFT_SIDEBAR_TYPE_CONDENSED;
  const isLight = leftSideBarTheme === layoutConstants.LEFT_SIDEBAR_THEME_LIGHT;

  // hack :\
  useEffect(() => {
    setTimeout(() => {
      const menuClose = document.getElementById("clientPortalMobileMenu");
      if (sidebarOpen) {
        setMenuRef(menuClose);
      } else {
        setMenuRef("");
      }
    });
  }, [sidebarOpen]);

  const handleCloseSideMenu = () => {
    if (menuRef) {
      setSidebarOpen(false);
    }
  };

  return (
    <>
      {errorIs && <Redirect to={"/error-404-alt"}></Redirect>}
      <div className="wrapper">
        {/*<Suspense fallback={loading()}>
                    <LeftSidebar isCondensed={isCondensed} isLight={isLight} hideUserProfile={false} />
                </Suspense>
                <div className="content-page">
                    <div className="content">
                         <Suspense fallback={loading()}>
                            <Topbar openLeftMenuCallBack={openMenu} hideLogo={true} />
                        </Suspense> 
                        <Container fluid>
                            <Suspense fallback={loading()}>{children}</Suspense>
                        </Container>
                    </div>

                    <Suspense fallback={loading()}>
                        <Footer />
                    </Suspense>
                </div>*/}

        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="fixed inset-0 flex z-40 md:hidden"
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
            </Transition.Child>
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <div className="relative flex-1 flex flex-col max-w-[12rem] w-full pb-4 dark-side-menu">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 right-0 pt-1">
                    <button
                      type="button"
                      className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                      onClick={() => setSidebarOpen(false)}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <XIcon
                        className="h-6 w-6 text-gray-500"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                </Transition.Child>
                <div
                  id="clientPortalMobileMenu"
                  className="mt-4 flex-1 h-0 overflow-y-auto"
                >
                  <Suspense fallback={loading()}>
                    <LeftSidebar
                      isCondensed={isCondensed}
                      closeSideBar={handleCloseSideMenu}
                      isLight={isLight}
                      hideUserProfile={false}
                    />
                  </Suspense>
                </div>
              </div>
            </Transition.Child>
            <div className="flex-shrink-0 w-14" aria-hidden="true">
              {/* Dummy element to force sidebar to shrink to fit close icon */}
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="hidden md:flex md:w-28 md:flex-col md:fixed md:inset-y-0 dark-side-menu">
          <div className="flex flex-col flex-grow border-r divide-y-0 border-gray-200 overflow-y-auto">
            <div className="flex-grow flex flex-col overflow-x-hidden">
              <Suspense fallback={loading()}>
                <LeftSidebar
                  isCondensed={isCondensed}
                  closeSideBar={handleCloseSideMenu}
                  isLight={isLight}
                  hideUserProfile={false}
                />
              </Suspense>
            </div>
          </div>
        </div>

        <div className="md:pl-28 flex flex-col flex-1">
          <TopHeaderSearch setSidebarOpen={setSidebarOpen} />
          <main className="flex-1">
            <div className="px-4 pt-4 pb-4 content-page-t">
              <Suspense fallback={loading()}>{children}</Suspense>
            </div>
            {/*<Suspense fallback={loading()}>
                            <Footer />
                        </Suspense>*/}
          </main>
        </div>
      </div>

      {/* <Suspense fallback={loading()}>
                <RightSidebar>
                    <ThemeCustomizer />
                </RightSidebar>
            </Suspense> */}
    </>
  );
};
export default VerticalLayout;
