import { APICore } from "../helpers/api/apiCore";

const INVOICES = "/invoices";
const ESTIMATES = "/estimates";
const PAYMENT_LINKS = "/subscriptions";
const DONATIONS = "/donations";

const GlobalSearch = {
  search: (term, page = 1, limit = 10) => {
    const api = new APICore();
    const requests = [];
    requests.push(api.get(`${INVOICES}`, { term, page, limit }));
    requests.push(api.get(`${ESTIMATES}`, { term, page, limit }));
    requests.push(api.get(`${PAYMENT_LINKS}`, { term, page, limit }));
    requests.push(api.get(`${DONATIONS}`, { term, page, limit }));
    return Promise.all(requests);
  },
};

export default GlobalSearch;
