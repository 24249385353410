// @flow
import { PaymentActionTypes } from "./constants";

type PaymentAction = { type: string, payload: {} | string };

// common success
export const paymentApiResponseSuccess = (
  actionType: string,
  data: any
): PaymentAction => ({
  type: PaymentActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});
// common error
export const paymentApiResponseError = (
  actionType: string,
  error: string
): PaymentAction => ({
  type: PaymentActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const paymentGateWayList = (): PaymentAction => ({
  type: PaymentActionTypes.PAYMENT_GATEWAY_ALL,
  payload: {},
});

export const paymentSourceAll = (): PaymentAction => ({
  type: PaymentActionTypes.SOURCES_ALL,
  payload: {},
});

export const paymentGatewayAppFetch = (name): PaymentAction => ({
  type: PaymentActionTypes.PAYMENT_GATEWAY_APP,
  payload: { name },
});

export const cyberCreatePublicKey = (id, host): PaymentAction => ({
  type: PaymentActionTypes.CREATE_CYBER_PUBLIC_KEY,
  payload: { id, host },
});

export const paymentSourceFetch = (source_id): PaymentAction => ({
  type: PaymentActionTypes.SOURCES_FETCH,
  payload: { source_id },
});

export const addCard = (
  id,
  methodId,
  token,
  address,
  city,
  state,
  zipCode,
  country,
  defaultSource,
  metadata = ""
): PaymentAction => ({
  type: PaymentActionTypes.ADD_CARD,
  payload: {
    id,
    methodId,
    token,
    address,
    city,
    state,
    zipCode,
    country,
    defaultSource,
    metadata,
  },
});

export const saveBankAccount = (
  id,
  methodId,
  token,
  nameOnAccount,
  accountType,
  accountHolderType,
  routingNumber,
  accountNumber,
  defaultSource
): PaymentAction => ({
  type: PaymentActionTypes.SAVE_BANK_ACCOUNT,
  payload: {
    id,
    methodId,
    token,
    nameOnAccount,
    accountType,
    accountHolderType,
    routingNumber,
    accountNumber,
    defaultSource,
  },
});

export const verifyBankAccount = (
  id,
  sourceId,
  deposit1,
  deposit2
): PaymentAction => ({
  type: PaymentActionTypes.VERIFY_BANK_ACCOUNT,
  payload: { id, sourceId, deposit1, deposit2 },
});

export const deleteCard = (id, sourceId): PaymentAction => ({
  type: PaymentActionTypes.DELETE_CARD,
  payload: { id, sourceId },
});

export const defaultCard = (id, sourceId): PaymentAction => ({
  type: PaymentActionTypes.DEFAULT_CARD,
  payload: { id, sourceId },
});

export const stripePaymentMethodIdentity = (id, methodId): PaymentAction => ({
  type: PaymentActionTypes.CREATE_PAYMENT_SOURCE_IDENTIFY,
  payload: { id, methodId },
});

export const stripeInvoiceIdentity = (
  id,
  fullName,
  address,
  city,
  state,
  zipCode,
  country
): PaymentAction => ({
  type: PaymentActionTypes.CREATE_INVOICE_IDENTIFY,
  payload: { id, fullName, address, city, state, zipCode, country },
});

export const stripeLinkDonateIdentity = (
  paymentGateway,
  id,
  type,
  quantity,
  amount,
  phoneCode,
  phoneNumber,
  recurringDonation,
  billingAddressDiff,
  billingAddress,
  billingCity,
  billingState,
  billingZipCode,
  billingCountry,
  firstName,
  lastName,
  email,
  address,
  city,
  state,
  zipCode,
  country,
  fullName,
  anonymous,
  shippingAddress,
  shippingCity,
  shippingState,
  shippingZipCode,
  shippingCountry,
  customFields = {}
): PaymentAction => ({
  type: PaymentActionTypes.CREATE_LINK_DONATION_IDENTIFY,
  payload: {
    paymentGateway,
    id,
    type,
    quantity,
    amount,
    phoneCode,
    phoneNumber,
    recurringDonation,
    billingAddressDiff,
    billingAddress,
    billingCity,
    billingState,
    billingZipCode,
    billingCountry,
    firstName,
    lastName,
    email,
    address,
    city,
    state,
    zipCode,
    country,
    fullName,
    anonymous,
    shippingAddress,
    shippingCity,
    shippingState,
    shippingZipCode,
    shippingCountry,
    customFields,
  },
});

export const braintreeCreateToken = (id): PaymentAction => ({
  type: PaymentActionTypes.CREATE_BRAINTREE_TOKEN,
  payload: { id },
});

export const payPapCreateClientToken = (id): PaymentAction => ({
  type: PaymentActionTypes.CREATE_PAYPAL_CLIENT_TOKEN,
  payload: { id },
});

export const payPalCreateOrder = (
  id,
  type,
  quantity,
  amount
): PaymentAction => ({
  type: PaymentActionTypes.CREATE_PAYPAL_ORDER,
  payload: { id, type, quantity, amount },
});

export const resetPayment = (): PaymentAction => ({
  type: PaymentActionTypes.RESET,
  payload: {},
});
