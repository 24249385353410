// @flow
import { all, fork, put, takeEvery, call } from "redux-saga/effects";

import {
  invoicesList as invoiceListApi,
  invoicesListAll as invoiceListAllApi,
  invoicesFetch as invoiceFetchApi,
  invoiceDueAmmount as invoiceDueAmmountApi,
  payInvoiceWithClientSource as payInvoiceWithClientSourceApi,
  payOfflineInvoice as payOfflineInvoiceApi,
  payInvoiceCybersource as payInvoiceCybersourceApi,
  payInvoicePaypal as payInvoicePaypal,
  payInvoiceBrainTree as payInvoiceBrainTreeApi,
  payInvoiceAuthorize as payInvoiceAuthorizeApi,
  payInvoiceStripe as payInvoiceStripeApi,
  markInvoiceViewed as markInvoiceViewedApi,
  payInvoiceOpenNode as payInvoiceOpenNodeApi,
  payInvoiceNmi as payInvoiceNmiApi,
} from "../../helpers";

import { invoiceApiResponseSuccess, invoiceApiResponseError } from "./actions";
import { InvoiceActionTypes } from "./constants";

/**
 * Login the user
 * @param {*} payload - username and password
 */

function* invoicesList({ payload: {} }) {
  try {
    const response = yield call(invoiceListApi, {});
    if (response?.status) {
      const invoices = response.data?.data;
      // NOTE - You can change this according to response format from your api

      yield put(
        invoiceApiResponseSuccess(InvoiceActionTypes.INVOICES, invoices)
      );
    } else {
      yield put(
        invoiceApiResponseError(
          InvoiceActionTypes.INVOICES,
          "Unable to load invoices"
        )
      );
    }
  } catch (error) {
    //console.log(error);
    yield put(invoiceApiResponseError(InvoiceActionTypes.ACCOUNT, error));
  }
}

function* invoicesListAll({ payload: {} }) {
  try {
    const response = yield call(invoiceListAllApi, {});
    //console.log(response);
    if (response?.status) {
      const invoices = response.data?.data;
      // NOTE - You can change this according to response format from your api

      yield put(
        invoiceApiResponseSuccess(InvoiceActionTypes.INVOICES_ALL, invoices)
      );
    } else {
      yield put(
        invoiceApiResponseError(
          InvoiceActionTypes.INVOICES_ALL,
          "Unable to load invoices"
        )
      );
    }
  } catch (error) {
    //console.log(error);
    yield put(invoiceApiResponseError(InvoiceActionTypes.INVOICES_ALL, error));
  }
}

function* invoicesFetch({ payload: { invoice_id } }) {
  try {
    const response = yield call(invoiceFetchApi, { invoice_id });
    if (response?.status) {
      const invoice = response.data?.data;
      // NOTE - You can change this according to response format from your api

      yield put(
        invoiceApiResponseSuccess(InvoiceActionTypes.INVOICES_FETCH, invoice)
      );
    } else {
      yield put(
        invoiceApiResponseError(
          InvoiceActionTypes.INVOICES_FETCH,
          "Failed to load the invoice details. Please, refresh the page or try again later."
        )
      );
    }
  } catch (error) {
    //console.log(error);
    yield put(
      invoiceApiResponseError(InvoiceActionTypes.INVOICES_FETCH, error)
    );
  }
}

function* invoiceDueAmmount({ payload: { id } }) {
  try {
    const response = yield call(invoiceDueAmmountApi, { id });
    if (response?.status) {
      const invoice = response.data?.data;
      // NOTE - You can change this according to response format from your api

      yield put(
        invoiceApiResponseSuccess(
          InvoiceActionTypes.INVOICES_DUE_AMOUNT,
          invoice
        )
      );
    } else {
      yield put(
        invoiceApiResponseError(
          InvoiceActionTypes.INVOICES_DUE_AMOUNT,
          "Unable to load invoice due amount. Please, try again later."
        )
      );
    }
  } catch (error) {
    //console.log(error);
    yield put(
      invoiceApiResponseError(InvoiceActionTypes.INVOICES_DUE_AMOUNT, error)
    );
  }
}

function* markInvoiceViewed({ payload: { id } }) {
  try {
    const response = yield call(markInvoiceViewedApi, { id });
    if (response?.status) {
      const invoice = response.data?.data;
      // NOTE - You can change this according to response format from your api

      yield put(
        invoiceApiResponseSuccess(
          InvoiceActionTypes.MARK_INVOICE_VIEWED,
          invoice
        )
      );
    } else {
      yield put(
        invoiceApiResponseError(
          InvoiceActionTypes.MARK_INVOICE_VIEWED,
          "Unable to mark invoice viewed. Please, try again later."
        )
      );
    }
  } catch (error) {
    //console.log(error);
    yield put(
      invoiceApiResponseError(InvoiceActionTypes.MARK_INVOICE_VIEWED, error)
    );
  }
}

function* payInvoiceWithClientSource({
  payload: {
    id,
    sourceId,
    fullName,
    address,
    city,
    state,
    zipCode,
    country,
    paymentMode,
  },
}) {
  try {
    const response = yield call(payInvoiceWithClientSourceApi, {
      id,
      sourceId,
      fullName,
      address,
      city,
      state,
      zipCode,
      country,
      paymentMode,
    });
    if (response?.status && response?.data?.status) {
      //  alert("success"+JSON.stringify(response)+"dd"+response?.status);
      const invoice = response.data;
      // NOTE - You can change this according to response format from your api

      yield put(
        invoiceApiResponseSuccess(
          InvoiceActionTypes.PAY_INVOICE_WITH_CLIENT_SOURCE,
          invoice
        )
      );
    } else {
      //  alert("error"+JSON.stringify(response));
      // alert("error:=> " + JSON.stringify(  response.data.message));

      yield put(
        invoiceApiResponseError(
          InvoiceActionTypes.PAY_INVOICE_WITH_CLIENT_SOURCE,
          response.data.message
        )
      );
    }
  } catch {
    //console.log(error);
    // alert("error:=> " + JSON.stringify(  error));
    yield put(
      invoiceApiResponseError(
        InvoiceActionTypes.PAY_INVOICE_WITH_CLIENT_SOURCE,
        "An error occurred while trying to process the payment. Please, try again later."
      )
    );
  }
}

function* payInvoiceDirectly({
  payload: {
    paymentGateway,
    id,
    nonce,
    data,
    token,
    orderId,
    fullName,
    address,
    city,
    state,
    zipCode,
    country,
    billingAddress,
    billingCity,
    billingState,
    billingZipCode,
    billingCountry,
    paymentMode,
    saveCard,
    metadata,
  },
}) {
  try {
    var response = {};
    if (paymentGateway.vendor_name === "stripe") {
      response = yield call(payInvoiceStripeApi, {
        id,
        token,
        fullName,
        address,
        city,
        state,
        zipCode,
        country,
        billingAddress,
        billingCity,
        billingState,
        billingZipCode,
        billingCountry,
        paymentMode,
        saveCard,
      });
    } else if (paymentGateway.vendor_name === "authorizenet") {
      response = yield call(payInvoiceAuthorizeApi, {
        id,
        data,
        fullName,
        address,
        city,
        state,
        zipCode,
        country,
        billingAddress,
        billingCity,
        billingState,
        billingZipCode,
        billingCountry,
        paymentMode,
        saveCard,
      });
      //      response = yield call(payPaymentLinkAuthorize, {id,data,firstName,lastName,email,address,city,state,zipCode,country,fullName,shippingAddress,shippingCity,shippingState,shippingZipCode,shippingCountry,quantity  });
    } else if (paymentGateway.vendor_name === "nmi") {
      response = yield call(payInvoiceNmiApi, {
        id,
        token,
        fullName,
        address,
        city,
        state,
        zipCode,
        country,
        billingAddress,
        billingCity,
        billingState,
        billingZipCode,
        billingCountry,
        paymentMode,
        saveCard,
        metadata,
      });
      //      response = yield call(payPaymentLinkAuthorize, {id,data,firstName,lastName,email,address,city,state,zipCode,country,fullName,shippingAddress,shippingCity,shippingState,shippingZipCode,shippingCountry,quantity  });
    } else if (paymentGateway.vendor_name === "braintree") {
      response = yield call(payInvoiceBrainTreeApi, {
        id,
        nonce,
        fullName,
        address,
        city,
        state,
        zipCode,
        country,
        billingAddress,
        billingCity,
        billingState,
        billingZipCode,
        billingCountry,
        paymentMode,
        saveCard,
      });
    } else if (paymentGateway.vendor_name === "cybersource") {
      response = yield call(payInvoiceCybersourceApi, {
        id,
        token,
        fullName,
        address,
        city,
        state,
        zipCode,
        country,
        billingAddress,
        billingCity,
        billingState,
        billingZipCode,
        billingCountry,
        paymentMode,
        saveCard,
      });
    } else if (paymentGateway.vendor_name === "paypal") {
      response = yield call(payInvoicePaypal, {
        id,
        orderId,
        fullName,
        address,
        city,
        state,
        zipCode,
        country,
      });
    }
    console.log("response: " + JSON.stringify(response));

    if (response?.status && response?.data?.status) {
      const { data } = response;
      // NOTE - You can change this according to response format from your api
      // alert(JSON.stringify(donation));

      yield put(
        invoiceApiResponseSuccess(InvoiceActionTypes.PAY_INVOICE_DIRECTLY, data)
      );
    } else {
      yield put(
        invoiceApiResponseError(
          InvoiceActionTypes.PAY_INVOICE_DIRECTLY,
          response.data.message
        )
      );
    }
  } catch {
    //console.log(error);
    yield put(
      invoiceApiResponseError(
        InvoiceActionTypes.PAY_INVOICE_DIRECTLY,
        "An error occurred while trying to process the payment. Please, try again later."
      )
    );
  }
}

function* payInvoicePayPal({
  payload: {
    id,
    orderId,
    fullName,
    address,
    city,
    state,
    zipCode,
    country,
    source,
  },
}) {
  try {
    var response = {};

    response = yield call(payInvoicePaypal, {
      id,
      orderId,
      fullName,
      address,
      city,
      state,
      zipCode,
      country,
      source,
    });

    if (response?.status && response?.data?.status) {
      const { data } = response;
      // NOTE - You can change this according to response format from your api
      // alert(JSON.stringify(donation));

      yield put(
        invoiceApiResponseSuccess(InvoiceActionTypes.PAY_INVOICE_PAYPAL, data)
      );
    } else {
      yield put(
        invoiceApiResponseError(
          InvoiceActionTypes.PAY_INVOICE_PAYPAL,
          response.data.message
        )
      );
    }
  } catch {
    yield put(
      invoiceApiResponseError(
        InvoiceActionTypes.PAY_INVOICE_PAYPAL,
        "An error occurred while trying to process the payment. Please, try again later."
      )
    );
  }
}

function* payInvoiceOpenNode({
  payload: { id, fullName, address, city, state, zipCode, country },
}) {
  try {
    var response = {};

    response = yield call(payInvoiceOpenNodeApi, {
      id,
      fullName,
      address,
      city,
      state,
      zipCode,
      country,
    });

    if (response?.status && response?.data?.status) {
      const { data } = response;
      // NOTE - You can change this according to response format from your api
      // alert(JSON.stringify(donation));

      yield put(
        invoiceApiResponseSuccess(
          InvoiceActionTypes.PAY_INVOICE_OPEN_NODE,
          data
        )
      );
    } else {
      yield put(
        invoiceApiResponseError(
          InvoiceActionTypes.PAY_INVOICE_OPEN_NODE,
          response.data.message
        )
      );
    }
  } catch {
    //console.log(error);
    yield put(
      invoiceApiResponseError(
        InvoiceActionTypes.PAY_INVOICE_OPEN_NODE,
        "An error occurred while trying to process the payment. Please, try again later."
      )
    );
  }
}

function* payOfflineInvoice({
  payload: {
    id,
    expectedPaymentDate,
    expectedPaymentMethod,
    expectedPaymentReference,
  },
}) {
  try {
    const response = yield call(payOfflineInvoiceApi, {
      id,
      expectedPaymentDate,
      expectedPaymentMethod,
      expectedPaymentReference,
    });
    if (response?.status && response?.data?.status) {
      //  alert("success"+JSON.stringify(response)+"dd"+response?.status);
      const invoice = response.data;
      // NOTE - You can change this according to response format from your api

      yield put(
        invoiceApiResponseSuccess(
          InvoiceActionTypes.PAY_INVOICE_OFFLINE,
          invoice
        )
      );
    } else {
      //  alert("error"+JSON.stringify(response));
      // alert("error:=> " + JSON.stringify(  response.data.message));

      yield put(
        invoiceApiResponseError(
          InvoiceActionTypes.PAY_INVOICE_OFFLINE,
          response.data.message
        )
      );
    }
  } catch {
    //console.log(error);
    // alert("error:=> " + JSON.stringify(  error));
    yield put(
      invoiceApiResponseError(
        InvoiceActionTypes.PAY_INVOICE_OFFLINE,
        "Sorry! Unable to pay invoice this time. Please, try again later."
      )
    );
  }
}

/**
 * Logout the user
 */

export function* watchInvoicesList(): any {
  yield takeEvery(InvoiceActionTypes.INVOICES, invoicesList);
}

export function* watchInvoicesListAll(): any {
  yield takeEvery(InvoiceActionTypes.INVOICES_ALL, invoicesListAll);
}

export function* watchInvoicesFetch(): any {
  yield takeEvery(InvoiceActionTypes.INVOICES_FETCH, invoicesFetch);
}

export function* watchInvoiceDueAmmount(): any {
  yield takeEvery(InvoiceActionTypes.INVOICES_DUE_AMOUNT, invoiceDueAmmount);
}

export function* watchPayInvoiceWithClientSource(): any {
  yield takeEvery(
    InvoiceActionTypes.PAY_INVOICE_WITH_CLIENT_SOURCE,
    payInvoiceWithClientSource
  );
}

export function* watchMarkInvoiceViewed(): any {
  yield takeEvery(InvoiceActionTypes.MARK_INVOICE_VIEWED, markInvoiceViewed);
}

export function* watchPayOfflineInvoice(): any {
  yield takeEvery(InvoiceActionTypes.PAY_INVOICE_OFFLINE, payOfflineInvoice);
}

export function* watchPayInvoiceDirectly(): any {
  yield takeEvery(InvoiceActionTypes.PAY_INVOICE_DIRECTLY, payInvoiceDirectly);
}

export function* watchpayInvoicePayPal(): any {
  yield takeEvery(InvoiceActionTypes.PAY_INVOICE_PAYPAL, payInvoicePayPal);
}

export function* watchpayInvoiceOpenNode(): any {
  yield takeEvery(InvoiceActionTypes.PAY_INVOICE_OPEN_NODE, payInvoiceOpenNode);
}

function* invoiceSaga(): any {
  yield all([
    fork(watchInvoicesList),
    fork(watchInvoicesListAll),
    fork(watchInvoicesFetch),
    fork(watchInvoiceDueAmmount),
    fork(watchPayInvoiceWithClientSource),
    fork(watchPayOfflineInvoice),
    fork(watchPayInvoiceDirectly),
    fork(watchMarkInvoiceViewed),
    fork(watchpayInvoicePayPal),
    fork(watchpayInvoiceOpenNode),
  ]);
}

export default invoiceSaga;
