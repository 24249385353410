// @flow
import React, { useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { ChevronRightIcon } from "@heroicons/react/solid";
type BreadcrumbItems = {
  label: string,
  path: string,
  active?: boolean,
};

type PageTitleProps = {
  breadCrumbItems: Array<BreadcrumbItems>,
  title: string,
};

/**
 * PageTitle
 */
const PageTitle = (props: PageTitleProps): React$Element<any> => {
  useEffect(() => {
    // let account = api.getAccount();
    //  document.title = ` ${route}- ${account.business_name}`;
    //alert(isUser);
  }, []);

  return (
    <>
      {props.breadCrumbItems ? (
        <Row>
          <Col>
            <div className="page-title-box bb mb-3">
              <nav className="flex" aria-label="Breadcrumb">
                <ol role="list" className="flex items-center space-x-3">
                  {/*<li>
                <div>
                  <Link to={"/account"}>
                    <a href={""} className="text-gray-500 text-sm font-medium">
                      Account
                    </a>
                  </Link>
                </div>
              </li>*/}
                  {props.breadCrumbItems.map((item, index, arr) => (
                    <li key={item.label}>
                      {!item.active && (
                        <div className="flex items-center">
                          <a
                            href={item.path}
                            className="text-sm font-medium text-gray-500"
                            aria-current={item.active ? "page" : undefined}
                          >
                            {item.label}
                          </a>
                          {arr.length - 1 !== index && (
                            <ChevronRightIcon
                              className="flex-shrink-0 h-5 w-5 text-gray-400 ml-4"
                              aria-hidden="true"
                            />
                          )}
                        </div>
                      )}
                      {item.active && (
                        <div className="flex items-center">
                          <a
                            className="text-sm cursor-default text-gray-400 hover:text-gray-400"
                            aria-current={item.active ? "page" : undefined}
                          >
                            {item.label}
                          </a>
                          {arr.length - 1 !== index && (
                            <ChevronRightIcon
                              className="flex-shrink-0 h-5 w-5 text-gray-400 ml-4"
                              aria-hidden="true"
                            />
                          )}
                        </div>
                      )}
                    </li>
                  ))}
                </ol>
              </nav>
              <h1 className="page-title text-gray-900 fw-semi-bold">
                {props.title}
              </h1>
            </div>
          </Col>
        </Row>
      ) : (
        <></>
      )}
    </>
  );
};

export default PageTitle;
