// @flow
import { InvoiceActionTypes } from "./constants";

const INIT_STATE = {
  loading: false,
  data: {},
};

type InvoiceAction = {
  type: string,
  payload: { actionType?: string, data?: any, error?: string },
};
type State = { data?: {} | null, loading?: boolean, value?: boolean };

const Invoice = (state: State = INIT_STATE, action: InvoiceAction): any => {
  switch (action.type) {
    case InvoiceActionTypes.API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case InvoiceActionTypes.INVOICES: {
          return {
            ...state,
            data: action.payload.data,
            loading: false,
            error: null,
          };
        }
        case InvoiceActionTypes.INVOICES_ALL: {
          return {
            ...state,
            data: action.payload.data,
            loading: false,
            error: null,
          };
        }
        case InvoiceActionTypes.INVOICES_FETCH: {
          return {
            ...state,
            data: action.payload.data,
            loading: false,
            invoice_fetch_error: null,
          };
        }

        case InvoiceActionTypes.MARK_INVOICE_VIEWED: {
          return {
            ...state,
            mark_invoice_data: action.payload.data,
            loading: false,
            invoice_mark_error: null,
          };
        }

        case InvoiceActionTypes.INVOICES_DUE_AMOUNT: {
          return {
            ...state,
            due_ammount_data: action.payload.data,
            loading: false,
            error: null,
          };
        }

        case InvoiceActionTypes.PAY_INVOICE_WITH_CLIENT_SOURCE: {
          return {
            ...state,
            pay_invoice_data: action.payload.data,
            loading: false,
            error: null,
          };
        }

        case InvoiceActionTypes.PAY_INVOICE_DIRECTLY: {
          return {
            ...state,
            pay_invoice_data: action.payload.data,
            loading: false,
            error: null,
          };
        }

        case InvoiceActionTypes.PAY_INVOICE_PAYPAL: {
          return {
            ...state,
            pay_invoice_data: action.payload.data,
            loading: false,
            error: null,
          };
        }

        case InvoiceActionTypes.PAY_INVOICE_OPEN_NODE: {
          return {
            ...state,
            pay_invoice_open_node_data: action.payload.data,
            loading: false,
            error: null,
          };
        }

        case InvoiceActionTypes.PAY_INVOICE_OFFLINE: {
          return {
            ...state,
            pay_invoice_data: action.payload.data,
            loading: false,
            error: null,
          };
        }
        default:
          return { ...state };
      }

    case InvoiceActionTypes.API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case InvoiceActionTypes.INVOICES: {
          return {
            ...state,
            error: action.payload.error,

            loading: false,
          };
        }

        case InvoiceActionTypes.INVOICES_ALL: {
          return {
            ...state,
            error: action.payload.error,

            loading: false,
          };
        }

        case InvoiceActionTypes.INVOICES_FETCH: {
          return {
            ...state,
            invoice_fetch_error: action.payload.error,
            loading: false,
          };
        }

        case InvoiceActionTypes.INVOICES_DUE_AMOUNT: {
          return {
            ...state,
            error: action.payload.error,
            loading: false,
          };
        }

        case InvoiceActionTypes.MARK_INVOICE_VIEWED: {
          return {
            ...state,
            error: action.payload.error,
            loading: false,
          };
        }

        case InvoiceActionTypes.PAY_INVOICE_WITH_CLIENT_SOURCE: {
          return {
            ...state,
            error: action.payload.error,
            loading: false,
          };
        }

        case InvoiceActionTypes.PAY_INVOICE_DIRECTLY: {
          return {
            ...state,
            error: action.payload.error,
            loading: false,
          };
        }

        case InvoiceActionTypes.PAY_INVOICE_PAYPAL: {
          return {
            ...state,
            error: action.payload.error,
            loading: false,
          };
        }

        case InvoiceActionTypes.PAY_INVOICE_OPEN_NODE: {
          return {
            ...state,
            error: action.payload.error,
            loading: false,
          };
        }

        case InvoiceActionTypes.PAY_INVOICE_OFFLINE: {
          return {
            ...state,
            error: action.payload.error,
            loading: false,
          };
        }

        default:
          return { ...state };
      }

    case InvoiceActionTypes.INVOICES:
      return { ...state, loading: true, userLoggedIn: false };
    case InvoiceActionTypes.INVOICES_ALL:
      return { ...state, loading: true, account: null };
    case InvoiceActionTypes.INVOICES_FETCH:
      return { ...state, loading: true };
    case InvoiceActionTypes.INVOICES_DUE_AMOUNT:
      return { ...state, loading: true };
    case InvoiceActionTypes.MARK_INVOICE_VIEWED:
      return { ...state, loading: true };

    case InvoiceActionTypes.PAY_INVOICE_WITH_CLIENT_SOURCE:
      return { ...state, loading: true };

    case InvoiceActionTypes.PAY_INVOICE_DIRECTLY:
      return { ...state, loading: true };

    case InvoiceActionTypes.PAY_INVOICE_PAYPAL:
      return { ...state, loading: true };

    case InvoiceActionTypes.PAY_INVOICE_OPEN_NODE:
      return { ...state, loading: true };
    case InvoiceActionTypes.PAY_INVOICE_OFFLINE:
      return { ...state, loading: true };

    case InvoiceActionTypes.RESET:
      return {
        ...state,
        loading: false,
        error: null,
        data: null,
        mark_invoice_data: null,
        invoice_mark_error: null,
        due_ammount_data: null,
        pay_invoice_data: null,
        pay_invoice_open_node_data: null,
      };
    default:
      return { ...state };
  }
};

export default Invoice;
