// @flow
import { all, fork, put, takeEvery, call } from "redux-saga/effects";

import {
  profile as profileApi,
  login as loginApi,
  signup as signupApi,
  account as accountApi,
  forgotPassword as forgotPasswordApi,
  updateProfile as updateProfileApi,
  forgotPasswordConfirm,
} from "../../helpers/";

import {
  APICore,
  setAuthorization,
  getUserFromSession,
} from "../../helpers/api/apiCore";

import { authApiResponseSuccess, authApiResponseError } from "./actions";
import { AuthActionTypes } from "./constants";

const api = new APICore();
/**
 * Login the user
 * @param {*} payload - username and password
 */
function* login({ payload: { email, host } }) {
  try {
    // const response = yield call(loginApi, { email,host });
    // //console.log(response);
    // if(response?.status){
    // const token = response.data.token;
    // // NOTE - You can change this according to response format from your api
    // //api.setLoggedInUser(response.data);
    // setAuthorization(token);
    // yield put(authApiResponseSuccess(AuthActionTypes.LOGIN_USER, response.data));

    const response = yield call(loginApi, { email, host });
    if (response?.status) {
      //const user = response.data;

      // //console.log("token"+ user);
      // NOTE - You can change this according to response format from your api

      yield put(authApiResponseSuccess(AuthActionTypes.LOGIN_USER, response));
    } else {
      yield put(
        authApiResponseError(
          AuthActionTypes.LOGIN_USER,
          "We do not find any user associated with this email address in our system. Please, enter a valid email address."
        )
      );
      api.setLoggedInUser(null);
      setAuthorization(null);
    }
  } catch (error) {
    //console.log(error);
    yield put(authApiResponseError(AuthActionTypes.LOGIN_USER, error));
    api.setLoggedInUser(null);
    setAuthorization(null);
  }
}

function* updateProfile({
  payload: {
    first_name,
    last_name,
    company,
    email,
    phone_code,
    phone_number,
    address_line1,
    address_line2,
    city,
    state,
    zip_code,
    country,
  },
}) {
  try {
    // const response = yield call(loginApi, { email,host });
    // //console.log(response);
    // if(response?.status){
    // const token = response.data.token;
    // // NOTE - You can change this according to response format from your api
    // //api.setLoggedInUser(response.data);
    // setAuthorization(token);
    // yield put(authApiResponseSuccess(AuthActionTypes.LOGIN_USER, response.data));

    const response = yield call(updateProfileApi, {
      first_name,
      last_name,
      company,
      email,
      phone_code,
      phone_number,
      address_line1,
      address_line2,
      city,
      state,
      zip_code,
      country,
    });

    if (response?.status) {
      const user = response.data;

      yield put(authApiResponseSuccess(AuthActionTypes.UPDATE_PROFILE, user));
    } else {
      yield put(
        authApiResponseError(
          AuthActionTypes.UPDATE_PROFILE,
          "Sorry! Unable to update profile"
        )
      );
    }
  } catch (error) {
    yield put(authApiResponseError(AuthActionTypes.UPDATE_PROFILE, error));
  }
}

function* account({ payload: { host } }) {
  try {
    //alert("ress"+host)
    const response = yield call(accountApi, { host });
    if (response?.status) {
      // alert(JSON.stringify(response))
      const account = response.data;
      // NOTE - You can change this according to response format from your api
      api.setAccount(account.data);
      // alert(JSON.stringify(response));

      yield put(authApiResponseSuccess(AuthActionTypes.ACCOUNT, account));
    } else {
      //alert("ugyg"+JSON.stringify(response))

      yield put(
        authApiResponseError(AuthActionTypes.ACCOUNT, "Invalid Account")
      );
      api.setAccount(account);
    }
  } catch (error) {
    // alert("gfhgfhgf")
    //console.log(error);
    yield put(authApiResponseError(AuthActionTypes.ACCOUNT, error));
    api.setAccount(account);
  }
}

function* profile({ payload: { token } }) {
  try {
    // const response = yield call(loginApi, { email,host });
    // //console.log(response);
    // if(response?.status){
    // const token = response.data.token;
    // // NOTE - You can change this according to response format from your api
    // //api.setLoggedInUser(response.data);
    // setAuthorization(token);
    // yield put(authApiResponseSuccess(AuthActionTypes.LOGIN_USER, response.data));
    if (token) {
      setAuthorization(token);
    }
    const response = yield call(profileApi, {});
    if (response?.status) {
      const user = response.data;
      if (token) {
        user.token = token;
      } else {
        let useris = getUserFromSession();
        user.token = useris.token;
      }
      ////console.log("token"+ user);
      // NOTE - You can change this according to response format from your api
      api.setLoggedInUser(user);
      setAuthorization(user.token);

      yield put(authApiResponseSuccess(AuthActionTypes.PROFILE, user));
      // }
      // else{

      //     yield put(authApiResponseError(AuthActionTypes.LOGIN_USER, "Unable to send email, please check email is valid."));
      //     api.setLoggedInUser(null);
      //     setAuthorization(null);
      // }
    } else {
      yield put(
        authApiResponseError(
          AuthActionTypes.PROFILE,
          "The link has expired. Please, enter your email address below to request a new link."
        )
      );
      api.setLoggedInUser(null);
      setAuthorization(null);
    }
  } catch (error) {
    //console.log(error);
    yield put(authApiResponseError(AuthActionTypes.PROFILE, error));
    api.setLoggedInUser(null);
    setAuthorization(null);
  }
}

/**
 * Logout the user
 */
function* logout() {
  try {
    //alert("");
    // yield call(logoutApi);
    api.setLoggedInUser(null);
    setAuthorization(null);
    yield put(authApiResponseSuccess(AuthActionTypes.LOGOUT_USER, {}));
  } catch (error) {
    yield put(authApiResponseError(AuthActionTypes.LOGOUT_USER, error));
  }
}

function* signup({ payload: { fullname, email, password } }) {
  try {
    const response = yield call(signupApi, { fullname, email, password });
    const user = response.data;
    // api.setLoggedInUser(user);
    // setAuthorization(user['token']);
    yield put(authApiResponseSuccess(AuthActionTypes.SIGNUP_USER, user));
  } catch (error) {
    yield put(authApiResponseError(AuthActionTypes.SIGNUP_USER, error));
    api.setLoggedInUser(null);
    setAuthorization(null);
  }
}

function* forgotPassword({ payload: { username } }) {
  try {
    const response = yield call(forgotPasswordApi, { username });
    yield put(
      authApiResponseSuccess(AuthActionTypes.FORGOT_PASSWORD, response.data)
    );
  } catch (error) {
    yield put(authApiResponseError(AuthActionTypes.FORGOT_PASSWORD, error));
  }
}

function* forgotPasswordChange({ payload: { data } }) {
  try {
    const response = yield call(forgotPasswordConfirm, data);
    yield put(
      authApiResponseSuccess(
        AuthActionTypes.FORGOT_PASSWORD_CHANGE,
        response.data
      )
    );
  } catch (error) {
    yield put(
      authApiResponseError(AuthActionTypes.FORGOT_PASSWORD_CHANGE, error)
    );
  }
}

export function* watchLoginUser(): any {
  yield takeEvery(AuthActionTypes.LOGIN_USER, login);
}

export function* watchProfile(): any {
  yield takeEvery(AuthActionTypes.PROFILE, profile);
}

export function* watchLogout(): any {
  yield takeEvery(AuthActionTypes.LOGOUT_USER, logout);
}

export function* watchSignup(): any {
  yield takeEvery(AuthActionTypes.SIGNUP_USER, signup);
}

export function* watchForgotPassword(): any {
  yield takeEvery(AuthActionTypes.FORGOT_PASSWORD, forgotPassword);
}

export function* watchForgotPasswordChange(): any {
  yield takeEvery(AuthActionTypes.FORGOT_PASSWORD_CHANGE, forgotPasswordChange);
}

export function* watchAccount(): any {
  yield takeEvery(AuthActionTypes.ACCOUNT, account);
}

export function* watchUpdateProfile(): any {
  yield takeEvery(AuthActionTypes.UPDATE_PROFILE, updateProfile);
}

function* authSaga(): any {
  yield all([
    fork(watchLoginUser),
    fork(watchLogout),
    fork(watchSignup),
    fork(watchForgotPassword),
    fork(watchForgotPasswordChange),
    fork(watchProfile),
    fork(watchAccount),
    fork(watchUpdateProfile),
  ]);
}

export default authSaga;
