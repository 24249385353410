import jwtDecode from "jwt-decode";
import axios from "axios";
import axiosdashboard from "axios";

import config from "../../config";

// content type
axios.defaults.headers.post["Content-Type"] = "application/json";
axiosdashboard.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.baseURL = config.API_URL;
axiosdashboard.defaults.baseURL = config.DASHBOARD_API_URL;

// intercepting to capture errors

axiosdashboard.interceptors.response.use(
  (response) => response,
  (error) => {
    let message;

    switch (error?.response?.status) {
      case 401:
        message = "Invalid credentials";
        break;
      case 403:
        message = "Access Forbidden";
        break;
      case 404:
        message = "Sorry! the data you are looking for could not be found.";
        break;
      default: {
        message =
          error.response && error.response.data
            ? error.response.data["message"]
            : error.message || error;
      }
    }

    return Promise.reject(message);
  }
);

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    let message;

    switch (error?.response?.status) {
      case 401:
        message =
          "The link has expired. Please, enter your email address below to request a new link.";
        break;
      case 403:
        message = "Access Forbidden";
        break;
      case 404:
        message = "Sorry! the data you are looking for could not be found.";
        break;
      default: {
        message =
          error.response && error.response.data
            ? error.response.data["message"]
            : error.message || error;
      }
    }

    return Promise.reject(message);
  }
);

const AUTH_SESSION_KEY = "duplex_user";
const PAYPAL_ORDER = "paypal_order";
const AUTH_SESSION_ACCOUNT = "duplex_account";

/**
 * Sets the default authorization
 * @param {*} token
 */
const setAuthorization = (token) => {
  if (token) {
    axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    axiosdashboard.defaults.headers.common["Authorization"] = "Bearer " + token;
  } else {
    delete axios.defaults.headers.common["Authorization"];
    delete axiosdashboard.defaults.headers.common["Authorization"];
  }
};

const getUserFromSession = () => {
  const user = localStorage.getItem(AUTH_SESSION_KEY);

  return user ? (typeof user === "object" ? user : JSON.parse(user)) : null;
};

const getAccountFromSession = () => {
  const account = localStorage.getItem(AUTH_SESSION_ACCOUNT);

  return account && account !== "undefined"
    ? typeof account === "object"
      ? account
      : JSON.parse(account)
    : null;
};
class APICore {
  /**
   * Fetches data from given url
   */
  get = (url, params) => {
    axios.defaults.baseURL = config.API_URL;
    let response;
    // alert("calling"+url+params)
    if (params) {
      var queryString = params
        ? Object.keys(params)
            .map((key) => key + "=" + params[key])
            .join("&")
        : "";
      response = axios.get(`${url}?${queryString}`, params);
    } else {
      response = axios.get(`${url}`);
    }

    return response;
  };

  getDashboardApi = (url, params) => {
    let response;
    axiosdashboard.defaults.baseURL = config.DASHBOARD_API_URL;
    if (params) {
      var queryString = params
        ? Object.keys(params)
            .map((key) => key + "=" + params[key])
            .join("&")
        : "";
      response = axiosdashboard.get(`${url}?${queryString}`, params);
    } else {
      response = axiosdashboard.get(`${url}`);
    }
    return response;
  };

  getFile = (url, params) => {
    let response;
    if (params) {
      var queryString = params
        ? Object.keys(params)
            .map((key) => key + "=" + params[key])
            .join("&")
        : "";
      response = axios.get(`${url}?${queryString}`, { responseType: "blob" });
    } else {
      response = axios.get(`${url}`, { responseType: "blob" });
    }
    return response;
  };

  getMultiple = (urls, params) => {
    const reqs = [];
    let queryString = "";
    if (params) {
      queryString = params
        ? Object.keys(params)
            .map((key) => key + "=" + params[key])
            .join("&")
        : "";
    }

    for (const url of urls) {
      reqs.push(axios.get(`${url}?${queryString}`));
    }
    return axios.all(reqs);
  };

  /**
   * post given data to url
   */
  create = (url, data) => {
    //console.log("called" + config.API_URL);
    axios.defaults.baseURL = config.API_URL;

    // axios.defaults.baseURL = config.API_URL;
    return axios.post(url, data);
  };

  createDashboardApi = (url, data) => {
    axiosdashboard.defaults.baseURL = config.DASHBOARD_API_URL;
    //console.log("called" + config.API_URL);

    // axios.defaults.baseURL = config.API_URL;
    return axiosdashboard.post(url, data);
  };

  getEmpty = (url) => {
    //console.log("called" + config.API_URL);

    axios.defaults.baseURL = config.API_URL;
    return axios.get(url);
  };

  getWithBody = async (urlis) => {
    //console.log("called data-----> " + JSON.stringify(datais));

    // axios.defaults.baseURL = config.API_URL;
    // return axios.get(url,data);

    return await axios({
      method: "GET",
      data: { host: "localhost" }, // <---- This step it is important
      url: `${urlis}`,
    });
  };

  /**
   * Updates patch data
   */
  updatePatch = (url, data) => {
    return axios.patch(url, data);
  };

  /**
   * Updates data
   */
  update = (url, data) => {
    axios.defaults.baseURL = config.API_URL;
    return axios.put(url, data);
  };

  /**
   * Deletes data
   */
  delete = (url) => {
    return axios.delete(url);
  };

  /**
   * post given data to url with file
   */
  createWithFile = (url, data) => {
    axiosdashboard.defaults.baseURL = config.DASHBOARD_API_URL;
    const formData = new FormData();
    for (const k in data) {
      //console.log("key =>" + k + " data" + data[k]);
      formData.append(k, data[k]);
    }

    const configration = {
      headers: {
        ...axios.defaults.headers,
        "content-type": "multipart/form-data",
      },
    };
    return axiosdashboard.post(url, formData, configration);
  };

  /**
   * post given data to url with file
   */
  updateWithFile = (url, data) => {
    const formData = new FormData();
    for (const k in data) {
      formData.append(k, data[k]);
    }

    const config = {
      headers: {
        ...axios.defaults.headers,
        "content-type": "multipart/form-data",
      },
    };
    return axios.patch(url, formData, config);
  };

  isUserAuthenticated = () => {
    const user = this.getLoggedInUser();
    //console.log("User is: " + JSON.stringify(user));
    if (!user || (user && !user.token)) {
      //console.log("user not logged in, user not exist.");
      return false;
    }
    const decoded = jwtDecode(user.token);

    const currentTime = Date.now() / 1000;

    {
      if (decoded.exp < currentTime) {
        // console.log(decoded.exp);
        // console.log("access token expired: "+user.token);
        // console.log("access token expired time: "+decoded.exp);
        return false;
      }

      {
        return true;
      }
    }
  };

  setLoggedInUser = (session) => {
    if (session)
      localStorage.setItem(AUTH_SESSION_KEY, JSON.stringify(session));
    else {
      localStorage.removeItem(AUTH_SESSION_KEY);
    }
  };

  setPayPalOrder = (session) => {
    if (session) localStorage.setItem(PAYPAL_ORDER, JSON.stringify(session));
    else {
      localStorage.removeItem(PAYPAL_ORDER);
    }
  };

  getPayPalOrder = () => {
    const account = localStorage.getItem(PAYPAL_ORDER);

    return account && account !== "undefined" ? account : null;
  };

  setAccount = (session) => {
    if (session)
      localStorage.setItem(AUTH_SESSION_ACCOUNT, JSON.stringify(session));
    else {
      localStorage.removeItem(AUTH_SESSION_ACCOUNT);
    }
  };

  getAccount = () => {
    return getAccountFromSession();
  };

  /**
   * Returns the logged in user
   */
  getLoggedInUser = () => getUserFromSession();

  getStatusSettings = () => {
    var statusInfo = {};
    const user = getUserFromSession();

    {
      if (!user || (user && !user.data)) {
        return undefined;
      }

      {
        statusInfo.display_business_name = user.data.display_business_name;
        statusInfo.modify_billing_info = user.data.modify_billing_info;

        return statusInfo;
      }
    }
  };

  getUserBillingInfo = () => {
    var billingInfo = {};
    const user = getUserFromSession();

    {
      if (!user || (user && !user.data)) {
        return undefined;
      }

      {
        billingInfo.identifier = user.data.identifier;
        billingInfo.address_line1 = user.data.address_line1;
        billingInfo.address_line2 = user.data.address_line2;
        billingInfo.city = user.data.city;
        billingInfo.state = user.data.state;
        billingInfo.zip_code = user.data.zip_code;
        billingInfo.country = user.data.country;
        billingInfo.vendor = user.data.vendor;
        billingInfo.user_id = user.data.billing_user_id;
        billingInfo.phone_code = user.data.phone_code;
        billingInfo.phone_number = user.data.phone_number;
        billingInfo.email = user.data.email;
        billingInfo.company = user.data.company;
        billingInfo.first_name = user.data.first_name;
        billingInfo.last_name = user.data.last_name;

        billingInfo.display_business_name =
          user.data.account.display_business_name;
        billingInfo.modify_billing_info = user.data.account.modify_billing_info;

        billingInfo.billing_address_same = user.data.billing_address_same;
        billingInfo.billing_address_line1 = user.data.billing_address_line1;
        billingInfo.billing_address_line2 = user.data.billing_address_line2;
        billingInfo.billing_city = user.data.billing_city;
        billingInfo.billing_state = user.data.billing_state;
        billingInfo.billing_zip_code = user.data.billing_zip_code;
        billingInfo.billing_country = user.data.billing_country;

        return billingInfo;
      }
    }
  };

  getVendorAddress = () => {
    var billingInfo = {};
    const account = this.getAccount();

    {
      if (!account) {
        return undefined;
      }

      {
        billingInfo.business_name = account.business_name;
        billingInfo.address_line1 = account.address_line1;
        billingInfo.address_line2 = account.address_line2;
        billingInfo.city = account.city;
        billingInfo.state = account.state;
        billingInfo.zip_code = account.zip_code;
        billingInfo.country = account.country;

        billingInfo.phone_number = account.contact_phone;
        billingInfo.email = account.contact_email;

        return billingInfo;
      }
    }
  };

  setUserInSession = (modifiedUser) => {
    let userInfo = localStorage.getItem(AUTH_SESSION_KEY);
    if (userInfo) {
      const { token, user } = JSON.parse(userInfo);
      this.setLoggedInUser({ token, ...user, ...modifiedUser });
    }
  };
}

/*
Check if token available in session
*/
let user = getUserFromSession();
if (user) {
  const { token } = user;
  if (token) {
    setAuthorization(token);
  }
}

export { APICore, setAuthorization, getUserFromSession };
