// @flow
import React from "react";
import { Row, Col } from "react-bootstrap";
// components
import PageTitle from "../../components/PageTitle";

import { getUserFromSession } from "../../helpers/api/apiCore";

const ErrorPageNotFoundAlt = (): React$Element<React$FragmentType> => {
  const data = getUserFromSession()?.data;
  return (
    <React.Fragment>
      {data && (
        <PageTitle
          breadCrumbItems={[
            { label: "404", path: "/pages/error-404", active: true },
          ]}
          title={"404 Error"}
        />
      )}

      <div
        className={`account-pages d-flex align-items-center justify-content-center overflow-hidden ${
          data ? "min-h-500" : "min-vh-100"
        }`}
      >
        <Row>
          <Col lg={12}>
            <div className="text-center">
              <h3 className="mt-2">Error! There's no page here!</h3>
              <p className="text-muted mt-2">
                Page does not exist or has been <br />
                removed.
              </p>
              {/* {getIsAccountExist() ? (
                  // <Link
                  //   className="btn btn-info mt-3"
                  //   to={"/account"}
                  //   href="https://www.dueplex.com/"
                  // >
                  //   <i className="mdi mdi-reply"></i> Return Home
                  // </Link>
                ) : (
                  // <a
                  //   className="btn btn-info mt-3"
                  //   href="https://www.dueplex.com/"
                  // >
                  //   <i className="mdi mdi-reply"></i> Return Home
                  // </a>
                )} */}
            </div>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default ErrorPageNotFoundAlt;
