import { MenuAlt2Icon } from "@heroicons/react/outline";
import { SearchIcon } from "@heroicons/react/solid";
import { Menu, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";
import MaterialIcon from "../../components/MaterialIcon";
import {
  getUserFullName,
  getUserEmail,
  roundAmount,
} from "../../helpers/data_helper";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import GlobalSearch from "../../services/GlobalSearch";
import utils from "../../helpers/utils";
import { Link } from "react-router-dom";
import { capitalize, getDifferenceInDays } from "../../helpers/helperfunctions";
import classNames from "classnames";

const userNavigation = [
  /*{ name: getUserFullName(), href: '#', icon: "account_circle" },*/
  { name: "Log out", href: "/account/logout", icon: "logout" },
];

function classNames_2(...classes) {
  return classes.filter(Boolean).join(" ");
}

const searchLabelTypes = {
  Invoices: { icon: "event", name: "Invoices" },
  Estimates: { icon: "format_quote", name: "Estimates" },
  PaymentLinks: { icon: "sell", name: "PaymentLinks" },
  Donations: { icon: "volunteer_activism", name: "Donations" },
};

const SearchTypeRoundAmount = ({ item, type }) => {
  let amount = "";
  if (type === searchLabelTypes.PaymentLinks.name) {
    if (item.paymentLink.type === "sell") {
      amount = roundAmount(item.amount / item.quantity) + "x" + item.quantity;
    } else {
      amount = roundAmount(item.total_paid);
    }
  } else if (type === searchLabelTypes.Donations.name) {
    amount = roundAmount(item.total_donated);
  } else {
    amount = roundAmount(item.total_amount);
  }
  return <span>{amount}</span>;
};

const SearchTypeStatus = ({ item, type }) => {
  {
    if (type === searchLabelTypes.Invoices.name) {
      let days = 0;
      if (item.real_due_date) {
        let due = new Date(item.real_due_date);
        days = Math.floor(getDifferenceInDays(due));
      }
      let isOverDue = days > 0 && item.status === "pending";

      item.invoiceStatus = isOverDue
        ? "Overdue"
        : item.refunded
        ? "Refunded"
        : item.partial_paid
        ? "Partially Paid"
        : item.status === "pending"
        ? "Unpaid"
        : capitalize(item.status);
      return (
        <p className="fw-semi-bold font-12 mb-0">
          <span
            className={classNames("", {
              "text-success": item.status === "paid" && !item.refunded,
              "text-dark": item.refunded,
              "text-secondary": item.cancelled,
              "text-danger": isOverDue,
              "text-gray-700": !isOverDue && item.status === "pending",
              "text-warning": item.partial_paid,
            })}
          >
            {isOverDue
              ? "Overdue"
              : item.refunded
              ? "Refunded"
              : item.partial_paid
              ? "Partial Paid"
              : item.status === "pending"
              ? "Unpaid"
              : capitalize(item.status)}
          </span>
        </p>
      );
    }

    {
      if (type === searchLabelTypes.Estimates.name) {
        let days = 0;
        let isOverDue = false;
        let openEstimates = 0;
        if (item.expire_date) {
          let due = new Date(item.expire_date);
          days = Math.round(getDifferenceInDays(due));
          //console.log("dfgdfgdsfg " + days);
        }

        isOverDue = days > 0 && item.status === "pending";

        item.estimateStatus = isOverDue ? "Expired" : capitalize(item.status);

        if (!isOverDue && item.status === "pending") {
          ++openEstimates;
        }

        return (
          <p className="fw-semi-bold font-12 mb-0">
            <span
              className={classNames("", {
                "text-success":
                  item.status === "accepted" || item.status === "invoiced",
                "text-danger": isOverDue,
                "text-black": !isOverDue && item.status === "pending",
              })}
            >
              {item.estimateStatus}
            </span>
          </p>
        );
      }

      if (type === searchLabelTypes.PaymentLinks.name) {
        if (item.frequency === "recurring" && item.next_billing_date) {
          // alert(item.next_billing_date)
          let due = new Date(item.next_billing_date);
          if (
            !(
              item.frequency === "recurring" &&
              item.paymentLink.recurring_frequency === "week"
            )
          ) {
            day = due.getDate();
          }
        }

        return (
          <p className="fw-semi-bold font-12 mb-0">
            <span
              className={classNames("", {
                "text-warning":
                  item.frequency === "recurring" &&
                  item.paymentLink.recurring_frequency === "week",
                "text-info":
                  item.frequency === "recurring" &&
                  item.paymentLink.recurring_frequency === "month",
                "text-success": item.frequency === "one-time",
              })}
            >
              {item.frequency === "one-time" && capitalize(item.frequency)}
              {item.frequency === "recurring" &&
                capitalize(item.paymentLink.recurring_frequency + "ly")}
            </span>
          </p>
        );
      } else {
        item.frequency === "recurring" && item.next_billing_date;

        return (
          <p className="fw-semi-bold font-12 mb-0">
            <span
              className={classNames("", {
                "text-warning": item.frequency === "recurring",
                "text-success": item.frequency === "one-time",
              })}
            >
              {capitalize(item.frequency)}
            </span>
          </p>
        );
      }
    }
  }
};

const SearchTypeIcon = ({ type }) => {
  const typeObject = searchLabelTypes[type];
  return <MaterialIcon icon={typeObject.icon} size="text-2xl" />;
};

const TopHeaderSearch = ({ setSidebarOpen }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState([]);

  const handleClick = (e, item) => {
    if (item.link === "#!") {
      // avoid clicking for donation/payment links
      e.preventDefault();
    } else {
      window.location.href = item.link;
    }
  };

  const handleSearch = (query) => {
    setIsLoading(true);
    GlobalSearch.search(query).then((responses) => {
      setIsLoading(false);

      let { invoices } = responses[0].data.data;
      let { estimates } = responses[1].data.data;
      let { subscriptions } = responses[2].data.data;
      let { donations } = responses[3].data.data;

      invoices = invoices.map((item) => ({
        ...item,
        displayLabel: utils.formatInvoiceId(item),
        type: searchLabelTypes.Invoices.name,
        link: `/invoice/${item.identifier}`,
      }));

      estimates = estimates.map((item) => ({
        ...item,
        displayLabel: utils.formatInvoiceId(item),
        type: searchLabelTypes.Estimates.name,
        link: `/estimate/${item.identifier}`,
      }));

      subscriptions = subscriptions.map((item) => ({
        ...item,
        invoice_id: item.id + "",
        displayLabel: item.paymentLink.title,
        type: searchLabelTypes.PaymentLinks.name,
        link: `#!`,
      }));

      donations = donations.map((item) => ({
        ...item,
        invoice_id: item.id + "",
        displayLabel: item.donationForm.title,
        type: searchLabelTypes.Donations.name,
        link: `#!`,
      }));

      const finalData = [
        ...invoices,
        ...estimates,
        ...subscriptions,
        ...donations,
      ];
      setOptions(finalData);
    });
  };

  // Bypass client-side filtering by returning `true`. Results are already
  // filtered by the search endpoint, so no need to do it again.
  const filterBy = () => true;
  return (
    <div className="flex-shrink-0 flex h-16 shadow-sm dark-side-menu">
      <button
        type="button"
        className="px-2 border-r border-gray-200 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden"
        onClick={() => setSidebarOpen(true)}
      >
        <span className="sr-only">Open sidebar</span>
        <MenuAlt2Icon className="h-6 w-6" aria-hidden="true" />
      </button>
      <div className="flex-1 pr-4 flex dark-side-menu justify-between">
        <div className="flex-1 flex">
          <form className="w-full flex md:ml-0" action="#" method="GET">
            <label htmlFor="search-field" className="sr-only">
              Search
            </label>
            <div className="relative w-full text-gray-400 focus-within:text-gray-600">
              <div className="absolute search-field-icon inset-y-0 left-0 flex z-20 items-center pointer-events-none">
                <SearchIcon className="h-5 w-5" aria-hidden="true" />
              </div>
              <AsyncTypeahead
                filterBy={filterBy}
                id="global-search"
                delay={100}
                isLoading={isLoading}
                labelKey="invoice_id"
                minLength={2}
                useCache={false}
                onSearch={handleSearch}
                options={options}
                placeholder="Search"
                renderMenuItemChildren={(option) => (
                  <Link
                    to={option.link}
                    onClick={(e) => handleClick(e, option)}
                  >
                    <div className="flex border-bottom items-center p-2 space-x-3">
                      <div>
                        <SearchTypeIcon type={option.type} />
                      </div>
                      <div>
                        <p className="font-13 link-hover fw-semibold mb-1">
                          {option.displayLabel}
                        </p>
                        <div className="flex text-gray-400 space-x-2 font-13 font-normal items-center">
                          <div>
                            {option.title && (
                              <span> {option.title} -&nbsp;</span>
                            )}
                            <SearchTypeRoundAmount
                              item={option}
                              type={option.type}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="flex-1 text-end">
                        <SearchTypeStatus item={option} type={option.type} />
                      </div>
                    </div>
                  </Link>
                )}
              />
            </div>
          </form>
        </div>
        <div className="ml-4 flex items-center md:ml-6">
          <Menu as="div" className="ml-3 relative">
            <div>
              <div className="max-w-xs dark-side-menu cursor-default rounded-full flex items-center text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-50 lg:p-2 lg:rounded-md lg:hover:bg-gray-50">
                <MaterialIcon icon={"account_circle"} size={"text-gray-600"} />
                <span className="hidden ml-3 text-gray-700 text-xs lg:block">
                  <span className="sr-only">Open user menu for </span>{" "}
                  <span className="lg:block capitalize fw-semibold">
                    {getUserFullName()}
                  </span>
                  <span className="lg:block text-gray-500">
                    {getUserEmail()}
                  </span>
                </span>
              </div>
            </div>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 dark-side-menu ring-1 ring-black ring-opacity-5 focus:outline-none">
                {userNavigation.map((item) => (
                  <Menu.Item key={item.name}>
                    {({ active }) => (
                      <a
                        href={item.href}
                        className={classNames_2(
                          active ? "bg-gray-100" : "",
                          "block py-2 px-3 space-x-2 flex items-center text-xs text-gray-700"
                        )}
                      >
                        <MaterialIcon icon={item.icon} />
                        <span>{item.name}</span>
                      </a>
                    )}
                  </Menu.Item>
                ))}
              </Menu.Items>
            </Transition>
          </Menu>
        </div>
      </div>
    </div>
  );
};

export default TopHeaderSearch;
