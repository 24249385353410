// @flow
import { EstimateActionTypes } from "./constants";

const INIT_STATE = {
  loading: false,
  data: {},
};

type EstimateAction = {
  type: string,
  payload: { actionType?: string, data?: any, error?: string },
};
type State = { data?: {} | null, loading?: boolean, value?: boolean };

const Estimate = (state: State = INIT_STATE, action: EstimateAction): any => {
  switch (action.type) {
    case EstimateActionTypes.API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case EstimateActionTypes.ESTIMATES: {
          return {
            ...state,
            data: action.payload.data,
            loading: false,
            error: false,
          };
        }
        case EstimateActionTypes.ESTIMATES_ALL: {
          return {
            ...state,
            data: action.payload.data,
            loading: false,
            error: false,
          };
        }
        case EstimateActionTypes.ESTIMATES_FETCH: {
          return {
            ...state,
            data: action.payload.data,
            loading: false,
            estimate_fetch_error: false,
          };
        }
        case EstimateActionTypes.ACCEPT_ESTIMATE: {
          return {
            ...state,
            estimate_accept_data: action.payload.data,
            loading: false,
            error: false,
          };
        }
        default:
          return { ...state };
      }

    case EstimateActionTypes.API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case EstimateActionTypes.ESTIMATES: {
          return {
            ...state,
            error: action.payload.error,

            loading: false,
          };
        }

        case EstimateActionTypes.ESTIMATES_ALL: {
          return {
            ...state,
            error: action.payload.error,

            loading: false,
          };
        }

        case EstimateActionTypes.ESTIMATES_FETCH: {
          return {
            ...state,
            estimate_fetch_error: action.payload.error,
            loading: false,
          };
        }

        case EstimateActionTypes.ACCEPT_ESTIMATE: {
          return {
            ...state,
            error: action.payload.error,
            loading: false,
          };
        }

        default:
          return { ...state };
      }

    case EstimateActionTypes.ESTIMATES:
      return { ...state, loading: true, userLoggedIn: false };
    case EstimateActionTypes.ESTIMATES_ALL:
      return { ...state, loading: true, account: null };
    case EstimateActionTypes.ESTIMATES_FETCH:
      return { ...state, loading: true };
    case EstimateActionTypes.ACCEPT_ESTIMATE:
      return { ...state, loading: true };

    case EstimateActionTypes.RESET:
      return {
        ...state,
        loading: false,
        error: false,
        data: null,
        estimate_accept_data: null,
      };
    default:
      return { ...state };
  }
};

export default Estimate;
