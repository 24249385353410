import config from "../config";
import moment from "moment";

import masterCardImg from "../assets/images/payments/mastercard.svg";
import discoverImg from "../assets/images/payments/discover.svg";
import visaCardImg from "../assets/images/payments/visa.svg";
import amexCardImg from "../assets/images/payments/american_express.svg";
import maestroImg from "../assets/images/payments/maestro.svg";
import dinersImg from "../assets/images/payments/diners.svg";
import jcbImg from "../assets/images/payments/jcb.svg";
import alipayImg from "../assets/images/payments/alipay.svg";
import hipercardImg from "../assets/images/payments/hipercard.svg";
import unionpayImg from "../assets/images/payments/unionpay.svg";
import eloImg from "../assets/images/payments/elo.svg";
import defaultCard from "../assets/images/payments/default.svg";

import applePayImg from "../assets/images/payments/apple_pay.svg";
import googlePayImg from "../assets/images/payments/google_pay.svg";
import paypalImg from "../assets/images/payments/paypal.svg";
import venmoImg from "../assets/images/payments/venmo.svg";
import bitcoinImg from "../assets/images/payments/bitcoin.svg";

const currencySymbolMap = require("./currencymap/map");

function getSymbolFromCurrency(currencyCode) {
  if (typeof currencyCode !== "string") {
    return undefined;
  }

  const code = currencyCode.toUpperCase();

  if (!Object.prototype.hasOwnProperty.call(currencySymbolMap, code)) {
    return undefined;
  }
  // alert(currencySymbolMap[code])
  return currencySymbolMap[code];
}
function getDifferenceInDays(date1) {
  const date2 = new Date();
  let diffInMs = -1;
  // if(date2 > date1){
  diffInMs = date2 - date1;
  // }
  return diffInMs / (1000 * 60 * 60 * 24);
}

function getEpiryDays(date1) {
  const date2 = new Date();
  let diffInMs = -1;
  date1 = new Date(date1);
  if (date1 > date2) {
    diffInMs = Math.abs(date1 - date2);
    // console.log(diffInMs);
  }

  return Math.ceil(diffInMs / (1000 * 60 * 60 * 24));
}

function formateDateList(date) {
  const now = new Date(date);
  const dateString = moment.utc(now).utc().format("MM/DD/YYYY");
  return dateString;
}

function formateDateShow(date) {
  const now = new Date(date);
  const dateString = moment(now).utc().format("MMMM DD, YYYY");
  return dateString;
}

function capitalize(s) {
  return s && s[0].toUpperCase() + s.slice(1);
}

function getCardTypeImage(cardType) {
  switch (cardType.toLowerCase()) {
    case "visa":
      return visaCardImg;
    case "mastercard":
      return masterCardImg;
    case "discover":
      return discoverImg;

    case "amex":
      return amexCardImg;
    case "jcb":
      return jcbImg;
    case " alipay":
      return alipayImg;
    case "diners":
      return dinersImg;
    case "maestro":
      return maestroImg;
    case "hipercard":
      return hipercardImg;
    case "elo":
      return eloImg;
    case "unionpay":
      return unionpayImg;
    default:
      return defaultCard;
  }
}

const getPaymentSourceImage = (source) => {
  switch (source.type.toLowerCase()) {
    case "card":
      return getCardTypeImage(source.brand);
    case "google_pay":
      return googlePayImg;
    case "apple_pay":
      return applePayImg;
    case "paypal":
      return paypalImg;
    case "venmo":
      return venmoImg;
    default:
      return defaultCard;
  }
};

const getPaymentMethodImage = (method, brand) => {
  switch (method.toLowerCase()) {
    case "credit card":
      return getCardTypeImage(brand);
    case "google pay":
      return googlePayImg;
    case "apple pay":
      return applePayImg;
    case "paypal":
    case "pay later":
    case "card":
      return paypalImg;
    case "venmo":
      return venmoImg;
    case "crypto":
      return bitcoinImg;
    default:
      return defaultCard;
  }
};

const numberToLast4 = (number) => {
  if (!number || number.length <= 4) {
    return number;
  }
  return number.substr(number.length - 4);
};

export {
  getSymbolFromCurrency,
  getCardTypeImage,
  getPaymentSourceImage,
  getPaymentMethodImage,
  getDifferenceInDays,
  formateDateList,
  formateDateShow,
  capitalize,
  getEpiryDays,
  numberToLast4,
};
