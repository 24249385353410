// @flow
import { AuthActionTypes } from "./constants";

type AuthAction = { type: string, payload: {} | string };

// common success
export const authApiResponseSuccess = (
  actionType: string,
  data: any
): AuthAction => ({
  type: AuthActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});
// common error
export const authApiResponseError = (
  actionType: string,
  error: string
): AuthAction => ({
  type: AuthActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const loginUser = (email: string, host: string): AuthAction => ({
  type: AuthActionTypes.LOGIN_USER,
  payload: { email, host },
});

export const updateProfile = (
  first_name: string,
  last_name: string,
  company: string,
  email: string,
  phone_code: string,
  phone_number: string,
  address_line1: string,
  address_line2: string,
  city: string,
  state: string,
  zip_code: string,
  country: string
): AuthAction => ({
  type: AuthActionTypes.UPDATE_PROFILE,
  payload: {
    first_name,
    last_name,
    company,
    email,
    phone_code,
    phone_number,
    address_line1,
    address_line2,
    city,
    state,
    zip_code,
    country,
  },
});
export const profile = (token): AuthAction => ({
  type: AuthActionTypes.PROFILE,
  payload: { token },
});

export const account = (host): AuthAction => ({
  type: AuthActionTypes.ACCOUNT,
  payload: { host },
});

export const logoutUser = (): AuthAction => ({
  type: AuthActionTypes.LOGOUT_USER,
  payload: {},
});

export const signupUser = (
  fullname: string,
  email: string,
  password: string
): AuthAction => ({
  type: AuthActionTypes.SIGNUP_USER,
  payload: { fullname, email, password },
});

export const forgotPassword = (username: string): AuthAction => ({
  type: AuthActionTypes.FORGOT_PASSWORD,
  payload: { username },
});

export const forgotPasswordChange = (username: string): AuthAction => ({
  type: AuthActionTypes.FORGOT_PASSWORD_CHANGE,
  payload: { username },
});

export const resetAuth = (): AuthAction => ({
  type: AuthActionTypes.RESET,
  payload: {},
});
