// Format Invoice ID
import config from "../config";
import { APICore } from "./api/apiCore";
const api = new APICore();

const formatInvoiceId = (invoice) => {
  if (invoice?.frequency === "one-time" && !invoice.parent_id) {
    return "INV-" + invoice.invoice_id;
  }

  return (
    "INV-" +
    invoice.invoice_id +
    "-R-" +
    (invoice.recurring_invoice_id || "0001")
  );
};

const formatEstimateId = (estimate) => {
  return "EST-" + estimate.invoice_id;
};

export function formatAmountForStripe(
  amount: number,
  currency: string
): number {
  const numberFormat = new Intl.NumberFormat(["en-US"], {
    style: "currency",
    currency,
    currencyDisplay: "symbol",
  });
  const parts = numberFormat.formatToParts(amount);
  let zeroDecimalCurrency: boolean = true;

  parts.forEach((part) => {
    if (part.type === "decimal") {
      zeroDecimalCurrency = false;
    }
  });
  return zeroDecimalCurrency ? amount : Math.round(amount * 100);
}

export const splitName = (name = "") => {
  if (!name) {
    return { firstName: null, lastName: null };
  }

  const [firstName, ...lastName] = name.split(" ").filter(Boolean);
  return {
    firstName: firstName,
    lastName: lastName.join(" "),
  };
};

export const addLeadingZeros = (num, totalLength) => {
  if (num < 0) {
    const withoutMinus = String(num).slice(1);
    return "-" + withoutMinus.padStart(totalLength, "0");
  }

  return String(num).padStart(totalLength, "0");
};

const hexToRgb = (hex) => {
  const aRgbHex = hex.match(/.{1,2}/g);
  return `${parseInt(aRgbHex[0], 16)}, ${parseInt(aRgbHex[1], 16)}, ${parseInt(
    aRgbHex[2],
    16
  )}`;
};

const changeTheme = (color) => {
  document.documentElement.style.setProperty("--bs-primary", color);
  document.documentElement.style.setProperty(
    "--bs-primary-rgb",
    hexToRgb(color)
  );
};

function getFaviconEl() {
  return document.getElementById("favicon");
}

const setMetaDescription = (desc) => {
  const el = document.querySelector("meta[name='description']");
  el.setAttribute("content", desc);
};

const changePageTitle = (title) => {
  const account = api.getAccount();
  // document.title = title+' - '+ account?.business_name;

  const setTitle = title || "Client Portal";

  if (account?.business_name) {
    document.title = setTitle + " - " + account?.business_name;
  } else {
    document.title = setTitle;
  }
};

const isAchPending = (invoiceData) => {
  if (
    invoiceData?.status === "pending" &&
    !invoiceData?.partial_paid &&
    invoiceData?.invoicePayments?.length > 0 &&
    invoiceData?.client_payment_source_id &&
    invoiceData?.invoicePayments[0].payment_method === "ACH" &&
    !invoiceData?.invoicePayments[0].refunded &&
    !invoiceData?.invoicePayments[0].confirmed &&
    !invoiceData?.invoicePayments[0].failed
  ) {
    return true;
  }

  return false;
};

const changeFavIcon = (icon) => {
  if (icon) {
    const favicon = getFaviconEl(); // Accessing favicon element
    favicon.href = config.APP_IMAGE_URI + icon;
  }
};

const setDefaultFavIcon = () => {
  const favicon = getFaviconEl(); // Accessing favicon element
  favicon.href = `${window.location.origin.toString()}/favicon.png`;
};

// by default round to 2
const roundAmount = (amount, radix = 2) => {
  // alert(amount);

  // let number = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(number)
  return amount?.toFixed(radix);
};

export const uuidv4 = () => {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  );
};

export const isCustomFields = (data) => {
  return Object.keys(data?.custom_fields || {}).length > 0;
};

export default {
  isAchPending,
  changePageTitle,
  setMetaDescription,
  setDefaultFavIcon,
  changeFavIcon,
  formatInvoiceId,
  formatEstimateId,
  hexToRgb,
  changeTheme,
  roundAmount,
  uuidv4,
  addLeadingZeros,
  isCustomFields,
};
