import React from "react";
import { Route } from "react-router-dom";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

const stripePromise = loadStripe(
  "pk_test_51JTRriLgIbmluKyLSUjK9Orjfbhe4amY6tjY2LhgYH5XLIthKHXsl7b0TxwQtgfrfNX0cBsGWATDznC42jlhsdsU00k5ciksqp"
);

/**
 * Private Route forces the authorization before the route can be accessed
 * @param {*} param0
 * @returns
 */
const PrivateRoute = ({ component: Component, roles, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      // authorised so return component
      return (
        <Elements stripe={stripePromise}>
          {" "}
          <Component {...props} />{" "}
        </Elements>
      );
    }}
  />
);

export default PrivateRoute;
