// @flow
import { all, fork, put, takeEvery, call } from "redux-saga/effects";

import {
  estimatesList as estimateListApi,
  estimatesListAll as estimateListAllApi,
  estimatesFetch as estimateFetchApi,
  estimateAccept as estimateAcceptApi,
} from "../../helpers";

import {
  estimateApiResponseSuccess,
  estimateApiResponseError,
} from "./actions";
import { EstimateActionTypes } from "./constants";

/**
 * Login the user
 * @param {*} payload - username and password
 */

function* estimatesList({ payload: {} }) {
  try {
    const response = yield call(estimateListApi, {});
    if (response?.status) {
      const estimates = response.data?.data;
      // NOTE - You can change this according to response format from your api

      yield put(
        estimateApiResponseSuccess(EstimateActionTypes.ESTIMATES, estimates)
      );
    } else {
      yield put(
        estimateApiResponseError(
          EstimateActionTypes.ESTIMATES,
          "Unable to load estimates"
        )
      );
    }
  } catch (error) {
    //console.log(error);
    yield put(estimateApiResponseError(EstimateActionTypes.ACCOUNT, error));
  }
}

function* estimatesListAll({ payload: {} }) {
  try {
    const response = yield call(estimateListAllApi, {});
    //console.log(response);
    if (response?.status) {
      const estimates = response.data?.data;
      // NOTE - You can change this according to response format from your api

      yield put(
        estimateApiResponseSuccess(EstimateActionTypes.ESTIMATES_ALL, estimates)
      );
    } else {
      yield put(
        estimateApiResponseError(
          EstimateActionTypes.ESTIMATES_ALL,
          "Unable to load estimates"
        )
      );
    }
  } catch (error) {
    //console.log(error);
    yield put(
      estimateApiResponseError(EstimateActionTypes.ESTIMATES_ALL, error)
    );
  }
}

function* estimatesFetch({ payload: { estimate_id } }) {
  try {
    const response = yield call(estimateFetchApi, { estimate_id });
    if (response?.status) {
      const estimate = response.data?.data;
      // NOTE - You can change this according to response format from your api
      // alert(JSON.stringify(estimate));

      yield put(
        estimateApiResponseSuccess(
          EstimateActionTypes.ESTIMATES_FETCH,
          estimate
        )
      );
    } else {
      yield put(
        estimateApiResponseError(
          EstimateActionTypes.ESTIMATES_FETCH,
          "Unable to load estimate"
        )
      );
    }
  } catch (error) {
    //console.log(error);
    yield put(
      estimateApiResponseError(EstimateActionTypes.ESTIMATES_FETCH, error)
    );
  }
}

function* accpetEstimate({ payload: { id } }) {
  try {
    const response = yield call(estimateAcceptApi, { id });
    if (response?.status && response?.data?.status) {
      const estimate = response.data;
      // NOTE - You can change this according to response format from your api
      // alert(JSON.stringify(estimate));

      yield put(
        estimateApiResponseSuccess(
          EstimateActionTypes.ACCEPT_ESTIMATE,
          estimate
        )
      );
    } else {
      yield put(
        estimateApiResponseError(
          EstimateActionTypes.ACCEPT_ESTIMATE,
          response.data.message
        )
      );
    }
  } catch (error) {
    //console.log(error);
    yield put(
      estimateApiResponseError(EstimateActionTypes.ACCEPT_ESTIMATE, error)
    );
  }
}

/**
 * Logout the user
 */

export function* watchEstimatesList(): any {
  yield takeEvery(EstimateActionTypes.ESTIMATES, estimatesList);
}

export function* watchEstimatesListAll(): any {
  yield takeEvery(EstimateActionTypes.ESTIMATES_ALL, estimatesListAll);
}

export function* watchEstimatesFetch(): any {
  yield takeEvery(EstimateActionTypes.ESTIMATES_FETCH, estimatesFetch);
}

export function* watchaccpetEstimate(): any {
  yield takeEvery(EstimateActionTypes.ACCEPT_ESTIMATE, accpetEstimate);
}

function* estimateSaga(): any {
  yield all([
    fork(watchEstimatesList),
    fork(watchEstimatesListAll),
    fork(watchEstimatesFetch),
    fork(watchaccpetEstimate),
  ]);
}

export default estimateSaga;
