// @flow
import { all } from "redux-saga/effects";

import authSaga from "./auth/saga";
import invoiceSaga from "./invoice/saga";
import estimateSaga from "./estimate/saga";
import layoutSaga from "./layout/saga";
import PaymentSaga from "./payment/saga";
import DonationSaga from "./donation/saga";

export default function* rootSaga(): any {
  yield all([
    DonationSaga(),
    authSaga(),
    invoiceSaga(),
    layoutSaga(),
    estimateSaga(),
    PaymentSaga(),
  ]);
}
