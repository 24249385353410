// @flow
import { all, fork, put, takeEvery, call } from "redux-saga/effects";

import {
  donationsList as donationListApi,
  donationsListAll as donationListAllApi,
  donationsFetch as donationFetchApi,
  subscriptioinListAll as subscriptioinListAllApi,
  paymentLink as paymentLinkApi,
  payPaymentLinkStripe as payPaymentLinkStripeApi,
  payPaymentLinkBrainTree as payPaymentLinkBrainTreeApi,
  payPaymentLinkCybersource as payPaymentLinkCybersourceApi,
  payPaymentLinkAuthorize as payPaymentLinkAuthorize,
  donationAcceptStripe as donationAcceptStripeApi,
  donationAcceptBrainTree as donationAcceptBrainTreeApi,
  donationAcceptAuthorize as donationAcceptAuthorizeApi,
  donationAcceptCybersource as donationAcceptCybersourceApi,
  donationAcceptNmi as donationAcceptNmiApi,
  countDonation as countDonationApi,
  countPaymentLink as countPaymentLinkApi,
  payPaymentLinkPayPal as payPaymentLinkPayPalApi,
  donationAcceptPaypal as donationAcceptPaypalApi,
  paymentLinkCancel as paymentLinkCancelApi,
  donationCancel as donationCancelApi,
  payPaymentLinkNmi as payPaymentLinkNmiApi,
} from "../../helpers";

import {
  donationApiResponseSuccess,
  donationApiResponseError,
} from "./actions";
import { DonationActionTypes } from "./constants";

/**
 * Login the user
 * @param {*} payload - username and password
 */

function* donationsList({ payload: {} }) {
  try {
    const response = yield call(donationListApi, {});
    if (response?.status) {
      const donations = response.data?.data;
      // NOTE - You can change this according to response format from your api

      yield put(
        donationApiResponseSuccess(DonationActionTypes.DONATIONS, donations)
      );
    } else {
      yield put(
        donationApiResponseError(
          DonationActionTypes.DONATIONS,
          "Unable to load donations"
        )
      );
    }
  } catch (error) {
    //console.log(error);
    yield put(donationApiResponseError(DonationActionTypes.ACCOUNT, error));
  }
}

function* donationsListAll({ payload: {} }) {
  try {
    const response = yield call(donationListAllApi, {});
    //console.log(response);
    if (response?.status) {
      const donations = response.data?.data;
      // NOTE - You can change this according to response format from your api

      yield put(
        donationApiResponseSuccess(DonationActionTypes.DONATIONS_ALL, donations)
      );
    } else {
      yield put(
        donationApiResponseError(
          DonationActionTypes.DONATIONS_ALL,
          "Unable to load donations"
        )
      );
    }
  } catch (error) {
    //console.log(error);
    yield put(
      donationApiResponseError(DonationActionTypes.DONATIONS_ALL, error)
    );
  }
}

function* subscriptioinListAll({ payload: {} }) {
  try {
    const response = yield call(subscriptioinListAllApi, {});
    console.log(response);
    if (response?.status) {
      const subscription = response.data?.data;
      // NOTE - You can change this according to response format from your api

      yield put(
        donationApiResponseSuccess(
          DonationActionTypes.SUBSCRIPTION_ALL,
          subscription
        )
      );
    } else {
      yield put(
        donationApiResponseError(
          DonationActionTypes.SUBSCRIPTION_ALL,
          "Unable to load donations"
        )
      );
    }
  } catch (error) {
    //console.log(error);
    yield put(
      donationApiResponseError(DonationActionTypes.SUBSCRIPTION_ALL, error)
    );
  }
}

function* donationsFetch({ payload: { donation_id } }) {
  try {
    const response = yield call(donationFetchApi, { donation_id });
    if (response?.status) {
      const donation = response.data?.data;
      // NOTE - You can change this according to response format from your api
      // alert(JSON.stringify(donation));

      yield put(
        donationApiResponseSuccess(
          DonationActionTypes.DONATIONS_FETCH,
          donation
        )
      );
    } else {
      yield put(
        donationApiResponseError(
          DonationActionTypes.DONATIONS_FETCH,
          "Unable to load donation"
        )
      );
    }
  } catch (error) {
    //console.log(error);
    yield put(
      donationApiResponseError(DonationActionTypes.DONATIONS_FETCH, error)
    );
  }
}

function* countDonation({ payload: { id } }) {
  try {
    const response = yield call(countDonationApi, { id });
    if (response?.status) {
      const donation = response.data?.data;
      // NOTE - You can change this according to response format from your api
      // alert(JSON.stringify(donation));

      yield put(
        donationApiResponseSuccess(DonationActionTypes.COUNT_DONATION, donation)
      );
    } else {
      yield put(
        donationApiResponseError(
          DonationActionTypes.COUNT_DONATION,
          "Unable to count donation"
        )
      );
    }
  } catch (error) {
    //console.log(error);
    yield put(
      donationApiResponseError(DonationActionTypes.COUNT_DONATION, error)
    );
  }
}

function* payPaymentLink({
  payload: {
    paymentGateway,
    id,
    nonce,
    data,
    token,
    orderId,
    firstName,
    lastName,
    email,
    address,
    city,
    state,
    zipCode,
    country,
    fullName,
    shippingAddress,
    shippingCity,
    shippingState,
    shippingZipCode,
    shippingCountry,
    quantity,
    metadata,
    customFields,
    source,
  },
}) {
  try {
    //alert("sending data======>"+paymentGateway.vendor_name);
    var response = {};
    if (paymentGateway.vendor_name === "stripe") {
      response = yield call(payPaymentLinkStripeApi, {
        id,
        token,
        firstName,
        lastName,
        email,
        address,
        city,
        state,
        zipCode,
        country,
        fullName,
        shippingAddress,
        shippingCity,
        shippingState,
        shippingZipCode,
        shippingCountry,
        quantity,
        customFields,
      });
    } else if (paymentGateway.vendor_name === "authorizenet") {
      response = yield call(payPaymentLinkAuthorize, {
        id,
        data,
        firstName,
        lastName,
        email,
        address,
        city,
        state,
        zipCode,
        country,
        fullName,
        shippingAddress,
        shippingCity,
        shippingState,
        shippingZipCode,
        shippingCountry,
        quantity,
        customFields,
      });
    } else if (paymentGateway.vendor_name === "braintree") {
      response = yield call(payPaymentLinkBrainTreeApi, {
        id,
        nonce,
        firstName,
        lastName,
        email,
        address,
        city,
        state,
        zipCode,
        country,
        fullName,
        shippingAddress,
        shippingCity,
        shippingState,
        shippingZipCode,
        shippingCountry,
        quantity,
        customFields,
      });
    } else if (paymentGateway.vendor_name === "nmi") {
      response = yield call(payPaymentLinkNmiApi, {
        id,
        token,
        firstName,
        lastName,
        email,
        address,
        city,
        state,
        zipCode,
        country,
        fullName,
        shippingAddress,
        shippingCity,
        shippingState,
        shippingZipCode,
        shippingCountry,
        quantity,
        metadata,
        customFields,
      });
    } else if (paymentGateway.vendor_name === "cybersource") {
      response = yield call(payPaymentLinkCybersourceApi, {
        id,
        token,
        firstName,
        lastName,
        email,
        address,
        city,
        state,
        zipCode,
        country,
        fullName,
        shippingAddress,
        shippingCity,
        shippingState,
        shippingZipCode,
        shippingCountry,
        quantity,
        customFields,
      });
    } else if (paymentGateway.vendor_name === "paypal") {
      response = yield call(payPaymentLinkPayPalApi, {
        id,
        orderId,
        firstName,
        lastName,
        email,
        address,
        city,
        state,
        zipCode,
        country,
        fullName,
        shippingAddress,
        shippingCity,
        shippingState,
        shippingZipCode,
        shippingCountry,
        quantity,
        customFields,
        source,
      });
    }
    if (response.data?.status) {
      //  alert(JSON.stringify(response))
      const payments = response?.data;
      //  alert("response"+JSON.stringify(payments));
      // NOTE - You can change this according to response format from your api

      yield put(
        donationApiResponseSuccess(
          DonationActionTypes.PAY_PAYMENT_LINK,
          payments
        )
      );
    } else {
      //alert(JSON.stringify(response.data.message))

      yield put(
        donationApiResponseError(
          DonationActionTypes.PAY_PAYMENT_LINK,
          response.data.message
        )
      );
    }
  } catch {
    //console.log(error);
    yield put(
      donationApiResponseError(
        DonationActionTypes.PAY_PAYMENT_LINK,
        "Unable to process your request, please try again later"
      )
    );
  }
}

function* paymentLink({ payload: { id } }) {
  try {
    const response = yield call(paymentLinkApi, { id });
    if (response?.status) {
      const donation = response.data?.data;
      // NOTE - You can change this according to response format from your api
      // alert(JSON.stringify("payment link response "+JSON.stringify(donation)));

      yield put(
        donationApiResponseSuccess(DonationActionTypes.PAYMENT_LINK, donation)
      );
    } else {
      yield put(
        donationApiResponseError(
          DonationActionTypes.PAYMENT_LINK,
          "Unable to load payment link"
        )
      );
    }
  } catch {
    //console.log(error);
    yield put(
      donationApiResponseError(
        DonationActionTypes.PAYMENT_LINK,
        "Unable to fetch payment link"
      )
    );
  }
}

function* countPaymentLink({ payload: { id } }) {
  try {
    const response = yield call(countPaymentLinkApi, { id });
    if (response?.status) {
      const donation = response.data?.data;
      // NOTE - You can change this according to response format from your api
      // alert(JSON.stringify("payment link response "+JSON.stringify(donation)));

      yield put(
        donationApiResponseSuccess(
          DonationActionTypes.COUNT_PAYMENT_LINK,
          donation
        )
      );
    } else {
      yield put(
        donationApiResponseError(
          DonationActionTypes.COUNT_PAYMENT_LINK,
          "Unable to load count payment link"
        )
      );
    }
  } catch {
    //console.log(error);
    yield put(
      donationApiResponseError(
        DonationActionTypes.COUNT_PAYMENT_LINK,
        "Unable to count payment link"
      )
    );
  }
}

function* cancelPaymentLink({ payload: { id } }) {
  try {
    const response = yield call(paymentLinkCancelApi, { id });
    if (response?.status) {
      const donation = response.data?.message;
      // NOTE - You can change this according to response format from your api

      if (response.data?.status) {
        yield put(
          donationApiResponseSuccess(
            DonationActionTypes.CANCEL_PAYMENT_LINK,
            donation
          )
        );
      } else {
        yield put(
          donationApiResponseError(
            DonationActionTypes.CANCEL_PAYMENT_LINK,
            response.data?.message
          )
        );
      }
    } else {
      yield put(
        donationApiResponseError(
          DonationActionTypes.CANCEL_PAYMENT_LINK,
          "Unable to cancel payment link"
        )
      );
    }
  } catch {
    //console.log(error);
    yield put(
      donationApiResponseError(
        DonationActionTypes.CANCEL_PAYMENT_LINK,
        "Unable to cancel payment link"
      )
    );
  }
}

function* cancelDonation({ payload: { id } }) {
  try {
    const response = yield call(donationCancelApi, { id });
    if (response?.status) {
      const donation = response.data?.message;
      // NOTE - You can change this according to response format from your api

      if (response.data?.status) {
        yield put(
          donationApiResponseSuccess(
            DonationActionTypes.CANCEL_DONATION,
            donation
          )
        );
      } else {
        yield put(
          donationApiResponseError(
            DonationActionTypes.CANCEL_DONATION,
            response.data?.message
          )
        );
      }
    } else {
      yield put(
        donationApiResponseError(
          DonationActionTypes.CANCEL_DONATION,
          "Unable to cancel subscription "
        )
      );
    }
  } catch {
    //console.log(error);
    yield put(
      donationApiResponseError(
        DonationActionTypes.CANCEL_PAYMENT_LINK,
        "Unable to cancel subscription"
      )
    );
  }
}

function* accpetDonation({
  payload: {
    paymentGateway,
    id,
    nonce,
    data,
    token,
    orderId,
    donationAmount,
    customAmount,
    firstName,
    lastName,
    email,
    phoneCode,
    phoneNumber,
    recurringDonation,
    address,
    city,
    state,
    zipCode,
    country,
    billingAddressDiff,
    billingAddress,
    billingCity,
    billingState,
    billingZipCode,
    billingCountry,
    anonymous,
    metadata,
    source,
  },
}) {
  try {
    var response = {};
    if (paymentGateway.vendor_name === "stripe") {
      response = yield call(donationAcceptStripeApi, {
        id,
        token,
        donationAmount,
        customAmount,
        firstName,
        lastName,
        email,
        phoneCode,
        phoneNumber,
        recurringDonation,
        address,
        city,
        state,
        zipCode,
        country,
        billingAddressDiff,
        billingAddress,
        billingCity,
        billingState,
        billingZipCode,
        billingCountry,
        anonymous,
      });
    } else if (paymentGateway.vendor_name === "authorizenet") {
      response = yield call(donationAcceptAuthorizeApi, {
        id,
        data,
        donationAmount,
        customAmount,
        firstName,
        lastName,
        email,
        phoneCode,
        phoneNumber,
        recurringDonation,
        address,
        city,
        state,
        zipCode,
        country,
        billingAddressDiff,
        billingAddress,
        billingCity,
        billingState,
        billingZipCode,
        billingCountry,
        anonymous,
      });
      //      response = yield call(payPaymentLinkAuthorize, {id,data,firstName,lastName,email,address,city,state,zipCode,country,fullName,shippingAddress,shippingCity,shippingState,shippingZipCode,shippingCountry,quantity  });
    } else if (paymentGateway.vendor_name === "braintree") {
      response = yield call(donationAcceptBrainTreeApi, {
        id,
        nonce,
        donationAmount,
        customAmount,
        firstName,
        lastName,
        email,
        phoneCode,
        phoneNumber,
        recurringDonation,
        address,
        city,
        state,
        zipCode,
        country,
        billingAddressDiff,
        billingAddress,
        billingCity,
        billingState,
        billingZipCode,
        billingCountry,
        anonymous,
      });
    } else if (paymentGateway.vendor_name === "nmi") {
      response = yield call(donationAcceptNmiApi, {
        id,
        token,
        donationAmount,
        customAmount,
        firstName,
        lastName,
        email,
        phoneCode,
        phoneNumber,
        recurringDonation,
        address,
        city,
        state,
        zipCode,
        country,
        billingAddressDiff,
        billingAddress,
        billingCity,
        billingState,
        billingZipCode,
        billingCountry,
        anonymous,
        metadata,
      });
    } else if (paymentGateway.vendor_name === "cybersource") {
      response = yield call(donationAcceptCybersourceApi, {
        id,
        token,
        donationAmount,
        customAmount,
        firstName,
        lastName,
        email,
        phoneCode,
        phoneNumber,
        recurringDonation,
        address,
        city,
        state,
        zipCode,
        country,
        billingAddressDiff,
        billingAddress,
        billingCity,
        billingState,
        billingZipCode,
        billingCountry,
        anonymous,
      });
    } else if (paymentGateway.vendor_name === "paypal") {
      response = yield call(donationAcceptPaypalApi, {
        id,
        orderId,
        donationAmount,
        customAmount,
        firstName,
        lastName,
        email,
        phoneCode,
        phoneNumber,
        recurringDonation,
        address,
        city,
        state,
        zipCode,
        country,
        billingAddressDiff,
        billingAddress,
        billingCity,
        billingState,
        billingZipCode,
        billingCountry,
        anonymous,
        source,
      });
    }
    if (response?.status && response?.data?.status) {
      const donation = response.data;
      // NOTE - You can change this according to response format from your api
      // alert(JSON.stringify(donation));

      yield put(
        donationApiResponseSuccess(
          DonationActionTypes.ACCEPT_DONATION,
          donation
        )
      );
    } else {
      yield put(
        donationApiResponseError(
          DonationActionTypes.ACCEPT_DONATION,
          response.data.message
        )
      );
    }
  } catch {
    //console.log(error);
    yield put(
      donationApiResponseError(
        DonationActionTypes.ACCEPT_DONATION,
        "Unable process your request"
      )
    );
  }
}

/**
 * Logout the user
 */

export function* watchDonationsList(): any {
  yield takeEvery(DonationActionTypes.DONATIONS, donationsList);
}

export function* watchDonationsListAll(): any {
  yield takeEvery(DonationActionTypes.DONATIONS_ALL, donationsListAll);
}

export function* watchDonationsFetch(): any {
  yield takeEvery(DonationActionTypes.DONATIONS_FETCH, donationsFetch);
}

export function* watchaccpetDonation(): any {
  yield takeEvery(DonationActionTypes.ACCEPT_DONATION, accpetDonation);
}

export function* watchsubscriptioinListAll(): any {
  yield takeEvery(DonationActionTypes.SUBSCRIPTION_ALL, subscriptioinListAll);
}

export function* watchpaymentLink(): any {
  yield takeEvery(DonationActionTypes.PAYMENT_LINK, paymentLink);
}

export function* watchpayPaymentLink(): any {
  yield takeEvery(DonationActionTypes.PAY_PAYMENT_LINK, payPaymentLink);
}

export function* watchcountPaymentLink(): any {
  yield takeEvery(DonationActionTypes.COUNT_PAYMENT_LINK, countPaymentLink);
}

export function* watchcountDonation(): any {
  yield takeEvery(DonationActionTypes.COUNT_DONATION, countDonation);
}

export function* watchCancelPaymentLink(): any {
  yield takeEvery(DonationActionTypes.CANCEL_PAYMENT_LINK, cancelPaymentLink);
}

export function* watchCancelDonation(): any {
  yield takeEvery(DonationActionTypes.CANCEL_DONATION, cancelDonation);
}

function* donationSaga(): any {
  yield all([
    fork(watchDonationsList),
    fork(watchDonationsListAll),
    fork(watchDonationsFetch),
    fork(watchaccpetDonation),
    fork(watchsubscriptioinListAll),
    fork(watchpaymentLink),
    fork(watchpayPaymentLink),
    fork(watchcountPaymentLink),
    fork(watchcountDonation),
    fork(watchCancelPaymentLink),
    fork(watchCancelDonation),
  ]);
}

export default donationSaga;
