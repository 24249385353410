// @flow
import { DonationActionTypes } from "./constants";

type DonationAction = { type: string, payload: {} | string };

// common success
export const donationApiResponseSuccess = (
  actionType: string,
  data: any
): DonationAction => ({
  type: DonationActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});
// common error
export const donationApiResponseError = (
  actionType: string,
  error: string
): DonationAction => ({
  type: DonationActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const donationList = (): DonationAction => ({
  type: DonationActionTypes.DONATIONS,
  payload: {},
});

export const donationListAll = (): DonationAction => ({
  type: DonationActionTypes.DONATIONS_ALL,
  payload: {},
});

export const donationFetch = (donation_id): DonationAction => ({
  type: DonationActionTypes.DONATIONS_FETCH,
  payload: { donation_id },
});

export const countPaymentLink = (id): DonationAction => ({
  type: DonationActionTypes.COUNT_PAYMENT_LINK,
  payload: { id },
});

export const cancelDonation = (id): DonationAction => ({
  type: DonationActionTypes.CANCEL_DONATION,
  payload: { id },
});

export const cancelPaymentLink = (id): DonationAction => ({
  type: DonationActionTypes.CANCEL_PAYMENT_LINK,
  payload: { id },
});

export const countDonation = (id): DonationAction => ({
  type: DonationActionTypes.COUNT_DONATION,
  payload: { id },
});

export const donationAccept = (
  paymentGateway,
  id,
  nonce,
  data,
  token,
  orderId,
  donationAmount,
  customAmount,
  firstName,
  lastName,
  email,
  phoneCode,
  phoneNumber,
  recurringDonation,
  address,
  city,
  state,
  zipCode,
  country,
  billingAddressDiff,
  billingAddress,
  billingCity,
  billingState,
  billingZipCode,
  billingCountry,
  anonymous,
  metadata = "",
  source = ""
): DonationAction => ({
  type: DonationActionTypes.ACCEPT_DONATION,
  payload: {
    paymentGateway,
    id,
    nonce,
    data,
    token,
    orderId,
    donationAmount,
    customAmount,
    firstName,
    lastName,
    email,
    phoneCode,
    phoneNumber,
    recurringDonation,
    address,
    city,
    state,
    zipCode,
    country,
    billingAddressDiff,
    billingAddress,
    billingCity,
    billingState,
    billingZipCode,
    billingCountry,
    anonymous,
    metadata,
    source,
  },
});

export const resetDonation = (): DonationAction => ({
  type: DonationActionTypes.RESET,
  payload: {},
});

export const subscriptionListAll = (): DonationAction => ({
  type: DonationActionTypes.SUBSCRIPTION_ALL,
  payload: {},
});

export const paymentLink = (id): DonationAction => ({
  type: DonationActionTypes.PAYMENT_LINK,
  payload: { id },
});

export const payPaymentLink = (
  paymentGateway,
  id,
  nonce,
  data,
  token,
  orderId,
  firstName,
  lastName,
  email,
  address,
  city,
  state,
  zipCode,
  country,
  fullName,
  shippingAddress,
  shippingCity,
  shippingState,
  shippingZipCode,
  shippingCountry,
  quantity,
  metadata = "",
  customFields = {},
  source = ""
): DonationAction => ({
  type: DonationActionTypes.PAY_PAYMENT_LINK,
  payload: {
    paymentGateway,
    id,
    nonce,
    data,
    token,
    orderId,
    firstName,
    lastName,
    email,
    address,
    city,
    state,
    zipCode,
    country,
    fullName,
    shippingAddress,
    shippingCity,
    shippingState,
    shippingZipCode,
    shippingCountry,
    quantity,
    metadata,
    customFields,
    source,
  },
});
