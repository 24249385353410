export const PaymentActionTypes = {
  API_RESPONSE_SUCCESS: "@@payment/API_RESPONSE_SUCCESS",
  API_RESPONSE_ERROR: "@@payment/API_RESPONSE_ERROR",

  SOURCES_ALL: "@@payment/SOURCES_ALL",
  SOURCES_FETCH: "@@payment/SOURCES_FETCH",
  PAYMENT_GATEWAY_ALL: "@@payment/PAYMENT_GATEWAY_ALL",
  PAYMENT_GATEWAY_APP: "@@payment/PAYMENT_GATEWAY_APP",

  ADD_CARD: "@@payment/ADD_CARD",
  DELETE_CARD: "@@payment/DELETE_CARD",
  DEFAULT_CARD: "@@payment/DEFAULT_CARD",

  CREATE_PAYMENT_SOURCE_IDENTIFY: "@@payment/CREATE_PAYMENT_SOURCE_IDENTIFY",
  CREATE_LINK_DONATION_IDENTIFY: "@@payment/CREATE_LINK_DONATION_IDENTIFY",
  CREATE_INVOICE_IDENTIFY: "@@payment/CREATE_INVOICE_IDENTIFY",

  CREATE_BRAINTREE_TOKEN: "@@payment/CREATE_BRAINTREE_TOKEN",
  CREATE_CYBER_PUBLIC_KEY: "@@payment/CREATE_CYBER_PUBLIC_KEY",
  SAVE_BANK_ACCOUNT: "@@payment/SAVE_BANK_ACCOUNT",
  VERIFY_BANK_ACCOUNT: "@@payment/VERIFY_BANK_ACCOUNT",

  CREATE_PAYPAL_CLIENT_TOKEN: "@@payment/CREATE_PAYPAL_CLIENT_TOKEN",
  CREATE_PAYPAL_ORDER: "@@payment/CREATE_PAYPAL_ORDER",

  RESET: "@@payment/RESET",
};
