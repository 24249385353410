import React, { useEffect } from "react";
import { Redirect } from "react-router-dom";
import { Route } from "react-router-dom";

import PrivateRoute from "./PrivateRoute";
import InvoicingRout from "./InvoicingRout";

import { useSelector } from "react-redux";

// lazy load all the views
import { APICore } from "../helpers/api/apiCore";
const api = new APICore();
// auth
const Login = React.lazy(() => import("../pages/account/Login"));
const Logout = React.lazy(() => import("../pages/account/Logout"));
const Confirm = React.lazy(() => import("../pages/account/Confirm"));
const ForgetPassword = React.lazy(() =>
  import("../pages/account/ForgetPassword")
);

// - ecommece pages

const MyAccount = React.lazy(() => import("../pages/myaccount"));
const InvoicePage = React.lazy(() => import("../pages/invoice"));
const Estimate = React.lazy(() => import("../pages/estimate"));
const Payments = React.lazy(() => import("../pages/Payments"));
const Payinvoice = React.lazy(() => import("../pages/Payinvoice"));
const paymentLink = React.lazy(() => import("../pages/paymentLink"));
const donation = React.lazy(() => import("../pages/donation"));

const ErrorPageNotFound = React.lazy(() =>
  import("../pages/error/PageNotFound")
);
const ErrorPageNotFoundAlt = React.lazy(() =>
  import("../pages/error/PageNotFoundAlt")
);
const ServerError = React.lazy(() => import("../pages/error/ServerError"));

const CustomComponent = (component, route): React$Element<any> => {
  return component;
};
// root routes
const rootRoute = {
  path: "/",
  exact: true,
  component: () => <Redirect to="/login" />,
  route: PrivateRoute,
};

// dashboards

// pages
const pageRoutes = {
  path: "/pages",
  name: "Pages",
  icon: "uil-copy-alt",
  header: "Custom",
  children: [
    {
      path: "/account",
      name: "My Account",
      component: CustomComponent(MyAccount, "My Account"),
      route: PrivateRoute,
    },
    {
      path: "/invoice/:id",
      name: "Invoice",
      component: CustomComponent(InvoicePage, "Invoice"),
      route: PrivateRoute,
    },

    {
      path: "/estimate/:id",
      name: "Estimate",
      component: CustomComponent(Estimate, "Estimate"),
      route: PrivateRoute,
    },
    {
      path: "/payments",
      name: "Billing & Payment info",
      component: CustomComponent(Payments, "Billing info"),
      route: PrivateRoute,
    },
    {
      path: "/Payinvoice",
      name: "Pay Invoice",
      component: CustomComponent(Payinvoice, "Pay Invoice"),
      route: PrivateRoute,
    },
  ],
};

// ui

const otherPublicRoutes = [
  {
    path: "/pay/:id",
    name: "Payment Link",
    component: CustomComponent(paymentLink, "Payment Link"),
    route: Route,
  },
  {
    path: "/donate/:id",
    name: "Donation ",
    component: CustomComponent(donation, "Donation"),
    route: Route,
  },
  {
    path: "/error-404-alt",
    name: "Error - 404",
    component: CustomComponent(ErrorPageNotFoundAlt, "Error - 404"),
    route: Route,
  },
  {
    path: "/error-500",
    name: "Error - 500",
    component: CustomComponent(ServerError, "Error - 500"),
    route: Route,
  },
  {
    path: "/account/confirm",
    name: "Confirm",
    component: CustomComponent(Confirm, "Confirm"),
    route: Route,
  },
];

// flatten the list of all nested routes
const flattenRoutes = (routes) => {
  let flatRoutes = [];

  routes = routes || [];
  routes.forEach((item) => {
    if (item) {
      flatRoutes.push(item);

      if (typeof item?.children !== "undefined") {
        flatRoutes = [...flatRoutes, ...flattenRoutes(item.children)];
      }
    }
  });
  return flatRoutes;
};

// auth
const authRoutes = [
  {
    path: "/login",
    name: "Login",
    component: CustomComponent(Login, "Login"),
    route: Route,
  },
  {
    path: "/account/logout",
    name: "Logout",
    component: CustomComponent(Logout, "Logout"),
    route: Route,
  },
  {
    path: "/account/forget-password",
    name: "Forget Password",
    component: ForgetPassword,
    route: Route,
  },
];

// All routes
const authProtectedRoutes = [rootRoute, , pageRoutes];
const publicRoutes = [...authRoutes, ...otherPublicRoutes];

const authProtectedFlattenRoutes = flattenRoutes([...authProtectedRoutes]);
const publicProtectedFlattenRoutes = flattenRoutes([...publicRoutes]);

export {
  publicRoutes,
  authProtectedRoutes,
  authProtectedFlattenRoutes,
  publicProtectedFlattenRoutes,
};
