// @flow
import React, { useEffect, Suspense } from "react";
import { Redirect } from "react-router-dom";
import { useSelector } from "react-redux";

const loading = () => <div className=""></div>;

type DefaultLayoutProps = {
  layout: {
    layoutType: string,
    layoutWidth: string,
    leftSideBarTheme: string,
    leftSideBarType: string,
    showRightSidebar: boolean,
  },
  user: any,
  data: any,
  children?: any,
};

const DefaultLayout = (props: DefaultLayoutProps): React$Element<any> => {
  const { errorIs } = useSelector((state) => ({
    loadingState: state.Auth.loading,

    accounDatat: state.Auth.account,
    errorIs: state.Auth.accountError,
  }));
  useEffect(() => {
    // if (document.body) document.body.classList.add('authentication-bg');
    // alert("jjjjjjjjjjjjjjjj")
    return () => {
      if (document.body) document.body.classList.remove("authentication-bg");
    };
  }, []);

  // get the child view which we would like to render
  const { children = null } = props;
  useEffect(() => {
    // if (document.body) document.body.classList.add('authentication-bg');
    // alert("jjjjjjjjjjjjjjjj")
    // alert(JSON.stringify("Child"));
  }, [children]);

  return (
    <>
      {errorIs && <Redirect to={"/error-404-alt"}></Redirect>}

      <Suspense fallback={loading()}>{children}</Suspense>
    </>
  );
};
export default DefaultLayout;
