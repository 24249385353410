// @flow
import { PaymentActionTypes } from "./constants";

const INIT_STATE = {
  loading: false,
  data: {},
  gateway_data: {},
  gateway_app_data: {},
  source_data: {},
  source_fetch_data: {},
};

type PaymentAction = {
  type: string,
  payload: { actionType?: string, data?: any, error?: string },
};
type State = {
  data?: {} | null,
  gateway_data?: {} | null,
  gateway_app_data?: {} | null,
  source_data?: {} | null,
  source_fetch_data?: {} | null,
  loading?: boolean,
  value?: boolean,
};

const Payment = (state: State = INIT_STATE, action: PaymentAction): any => {
  switch (action.type) {
    case PaymentActionTypes.API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case PaymentActionTypes.PAYMENT_GATEWAY_ALL: {
          return {
            ...state,
            gateway_data: action.payload.data,
            loading: false,
            error: null,
          };
        }
        case PaymentActionTypes.PAYMENT_GATEWAY_APP: {
          return {
            ...state,
            gateway_app_data: action.payload.data,
            loading: false,
            error: null,
          };
        }
        case PaymentActionTypes.SOURCES_ALL: {
          return {
            ...state,
            source_data: action.payload.data,
            loading: false,
            source_error: null,
          };
        }

        case PaymentActionTypes.SOURCES_FETCH: {
          return {
            ...state,
            source_fetch_data: action.payload.data,
            loading: false,
            error: null,
          };
        }

        case PaymentActionTypes.ADD_CARD: {
          return {
            ...state,
            card_add_data: action.payload.data,
            loading: false,
            error: null,
          };
        }

        case PaymentActionTypes.SAVE_BANK_ACCOUNT: {
          return {
            ...state,
            card_add_data: action.payload.data,
            loading: false,
            error: null,
          };
        }

        case PaymentActionTypes.VERIFY_BANK_ACCOUNT: {
          return {
            ...state,
            card_verify_data: action.payload.data,
            loading: false,
            error: null,
          };
        }

        case PaymentActionTypes.DEFAULT_CARD: {
          return {
            ...state,
            card_default_data: action.payload.data,
            loading: false,
            error: null,
          };
        }
        case PaymentActionTypes.DELETE_CARD: {
          return {
            ...state,
            card_delete_data: action.payload.data,
            loading: false,
            error: null,
          };
        }

        case PaymentActionTypes.CREATE_PAYMENT_SOURCE_IDENTIFY: {
          return {
            ...state,
            source_identity_data: action.payload.data,
            loading: false,
            error: null,
          };
        }

        case PaymentActionTypes.CREATE_LINK_DONATION_IDENTIFY: {
          return {
            ...state,
            source_identity_data: action.payload.data,
            loading: false,
            error: null,
          };
        }

        case PaymentActionTypes.CREATE_INVOICE_IDENTIFY: {
          return {
            ...state,
            source_identity_data: action.payload.data,
            loading: false,
            error: null,
          };
        }

        case PaymentActionTypes.CREATE_BRAINTREE_TOKEN: {
          return {
            ...state,
            braintree_token_data: action.payload.data,
            loading: false,
            error: null,
          };
        }

        case PaymentActionTypes.CREATE_PAYPAL_CLIENT_TOKEN: {
          return {
            ...state,
            paypal_token_data: action.payload.data,
            loading: false,
            error: null,
          };
        }

        case PaymentActionTypes.CREATE_PAYPAL_ORDER: {
          return {
            ...state,
            paypal_order_data: action.payload.data,
            loading: false,
            error: null,
          };
        }
        case PaymentActionTypes.CREATE_CYBER_PUBLIC_KEY: {
          return {
            ...state,
            cyber_key_data: action.payload.data,
            loading: false,
            error: null,
          };
        }

        default:
          return { ...state };
      }

    case PaymentActionTypes.API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case PaymentActionTypes.PAYMENT_GATEWAY_ALL: {
          return {
            ...state,
            error: action.payload.error,

            loading: false,
          };
        }

        case PaymentActionTypes.PAYMENT_GATEWAY_APP: {
          return {
            ...state,
            error: action.payload.error,

            loading: false,
          };
        }

        case PaymentActionTypes.SOURCES_ALL: {
          return {
            ...state,
            source_error: action.payload.error,
            loading: false,
          };
        }
        case PaymentActionTypes.SOURCES_FETCH: {
          return {
            ...state,
            error: action.payload.error,
            loading: false,
          };
        }
        case PaymentActionTypes.ADD_CARD: {
          return {
            ...state,
            error: action.payload.error,
            loading: false,
          };
        }

        case PaymentActionTypes.SAVE_BANK_ACCOUNT: {
          return {
            ...state,
            error: action.payload.error,
            loading: false,
          };
        }

        case PaymentActionTypes.VERIFY_BANK_ACCOUNT: {
          return {
            ...state,
            error: action.payload.error,
            loading: false,
          };
        }

        case PaymentActionTypes.DEFAULT_CARD: {
          return {
            ...state,
            error: action.payload.error,
            loading: false,
          };
        }
        case PaymentActionTypes.DELETE_CARD: {
          return {
            ...state,
            error: action.payload.error,
            loading: false,
          };
        }

        case PaymentActionTypes.CREATE_PAYMENT_SOURCE_IDENTIFY: {
          return {
            ...state,
            error: action.payload.error,
            loading: false,
          };
        }

        case PaymentActionTypes.CREATE_LINK_DONATION_IDENTIFY: {
          return {
            ...state,
            error: action.payload.error,
            loading: false,
          };
        }

        case PaymentActionTypes.CREATE_INVOICE_IDENTIFY: {
          return {
            ...state,
            error: action.payload.error,
            loading: false,
          };
        }

        case PaymentActionTypes.CREATE_BRAINTREE_TOKEN: {
          return {
            ...state,
            error: action.payload.error,
            loading: false,
          };
        }

        case PaymentActionTypes.CREATE_PAYPAL_CLIENT_TOKEN: {
          return {
            ...state,
            error: action.payload.error,
            loading: false,
          };
        }

        case PaymentActionTypes.CREATE_PAYPAL_ORDER: {
          return {
            ...state,
            error: action.payload.error,
            loading: false,
          };
        }

        case PaymentActionTypes.CREATE_CYBER_PUBLIC_KEY: {
          return {
            ...state,
            error: action.payload.error,
            loading: false,
          };
        }

        default:
          return { ...state };
      }

    case PaymentActionTypes.PAYMENT_GATEWAY_ALL:
      return { ...state, loading: true };
    case PaymentActionTypes.PAYMENT_GATEWAY_APP:
      return { ...state, loading: true };
    case PaymentActionTypes.SOURCES_ALL:
      return { ...state, loading: true };

    case PaymentActionTypes.SOURCES_FETCH:
      return { ...state, loading: true };
    case PaymentActionTypes.VERIFY_BANK_ACCOUNT:
      return { ...state, loading: true };
    case PaymentActionTypes.ADD_CARD:
      return { ...state, loading: true, error: null };
    case PaymentActionTypes.SAVE_BANK_ACCOUNT:
      return { ...state, loading: true };
    case PaymentActionTypes.DELETE_CARD:
      return { ...state, loading: true };

    case PaymentActionTypes.DEFAULT_CARD:
      return { ...state, loading: true };

    case PaymentActionTypes.CREATE_PAYMENT_SOURCE_IDENTIFY:
      return { ...state, loading: true };

    case PaymentActionTypes.CREATE_LINK_DONATION_IDENTIFY:
      return { ...state, loading: true };

    case PaymentActionTypes.CREATE_INVOICE_IDENTIFY:
      return { ...state, loading: true };

    case PaymentActionTypes.CREATE_BRAINTREE_TOKEN:
      return { ...state, loading: true };

    case PaymentActionTypes.CREATE_PAYPAL_CLIENT_TOKEN:
      return { ...state, loading: true };

    case PaymentActionTypes.CREATE_PAYPAL_ORDER:
      return { ...state, loading: true };

    case PaymentActionTypes.CREATE_CYBER_PUBLIC_KEY:
      return { ...state, loading: true };

    case PaymentActionTypes.RESET:
      return {
        ...state,
        loading: false,
        error: null,
        source_error: null,
        data: null,
        gateway_app_data: null,
        gateway_data: null,
        source_data: null,
        source__fetch_data: null,
        source_identity_data: null,
        paypal_token_data: null,
        card_delete_data: null,
        card_default_data: null,
        braintree_token_data: null,
        paypal_order_data: null,
        card_add_data: null,
        cyber_key_data: null,
        card_verify_data: null,
      };
    default:
      return { ...state };
  }
};

export default Payment;
