// @flow
import { APICore } from "./apiCore";

const api = new APICore();

// account

function paymentGatewayList(params: any): any {
  const baseUrl = "/payment-gateways";
  return api.getEmpty(`${baseUrl}`, params);
}

function paymentGatewayApp(params: any): any {
  const baseUrl = "/payment-gateways/app-name";
  return api.get(`${baseUrl}`, params);
}

function paymentSourceList(): any {
  const baseUrl = "/sources";
  //console.log("------------->"+baseUrl);
  return api.get(`${baseUrl}`, {});
}

function stripeMethodIdentity(params: any): any {
  const baseUrl = "/payments/stripe/create-payment-method-identifier";
  //console.log("------------->"+baseUrl);
  return api.createWithFile(`${baseUrl}`, params);
}

function stripeLinkDonationIdentity(params: any): any {
  const baseUrl = "/payments/stripe/create-payment-identifier";
  //console.log("------------->"+baseUrl);
  return api.createWithFile(`${baseUrl}`, params);
}

function stripeInvoiceIdentity(params: any): any {
  const baseUrl = "/payments/stripe/create-invoice-payment-identifier";
  //console.log("------------->"+baseUrl);
  return api.createWithFile(`${baseUrl}`, params);
}

function payPalCreateOrder(params: any): any {
  const baseUrl = "/payments/paypal/create-order";
  //console.log("------------->"+baseUrl);
  return api.createWithFile(`${baseUrl}`, params);
}

function paymentsSourceFetch(source_id) {
  //console.log("safasdfdasf"+ JSON.stringify(source_id.source_id));
  const baseUrl = "/sources/" + source_id.source_id;
  return api.get(`${baseUrl}`, {});
}

function addCard(params: any): any {
  const baseUrl = "/clients/sources/save-payment-method";
  return api.createWithFile(`${baseUrl}`, params);
}

function saveBankAccount(params: any): any {
  const baseUrl = "clients/sources/save-bank-account";
  return api.createWithFile(`${baseUrl}`, params);
}

function verifyBankAccount(params: any): any {
  const baseUrl = "clients/sources/verify-bank-account";
  return api.createWithFile(`${baseUrl}`, params);
}

function deleteCard(params: any): any {
  const baseUrl = "/clients/sources/delete-payment-method";
  return api.createWithFile(`${baseUrl}`, params);
}

function defaultCard(params: any): any {
  const baseUrl = "/clients/sources/update-default-method";
  //console.log("------------->"+baseUrl);
  return api.createWithFile(`${baseUrl}`, params);
}

function braintreeClientToken(params: any): any {
  const baseUrl = "/payments/braintree/create-client-token";
  //console.log("------------->"+baseUrl);
  return api.createWithFile(`${baseUrl}`, params);
}

function payPalCreateToken(params: any): any {
  const baseUrl = "/payments/paypal/create-client-token";
  //console.log("------------->"+baseUrl);
  return api.createWithFile(`${baseUrl}`, params);
}

function cyberCreatePublicKey(params: any): any {
  const baseUrl = "/payments/cybersource/create-public-key";
  //console.log("------------->"+baseUrl);
  return api.createWithFile(`${baseUrl}`, params);
}

export {
  stripeInvoiceIdentity,
  verifyBankAccount,
  stripeLinkDonationIdentity,
  saveBankAccount,
  addCard,
  deleteCard,
  defaultCard,
  paymentGatewayList,
  paymentGatewayApp,
  paymentSourceList,
  paymentsSourceFetch,
  braintreeClientToken,
  stripeMethodIdentity,
  cyberCreatePublicKey,
  payPalCreateToken,
  payPalCreateOrder,
};
