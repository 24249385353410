// @flow
import { APICore } from "./apiCore";

const api = new APICore();

// account
function estimatesList(params: any): any {
  const baseUrl = "/estimates";
  return api.getEmpty(`${baseUrl}`, params);
}

function estimatesListAll(): any {
  const baseUrl = "/estimates/all";
  //console.log("------------->"+baseUrl);
  return api.get(`${baseUrl}`, {});
}

function estimatesFetch(estimate_id) {
  //console.log("safasdfdasf"+ JSON.stringify(estimate_id.estimate_id));
  const baseUrl = "/estimates/" + estimate_id.estimate_id;
  return api.get(`${baseUrl}`, {});
}
function estimateAccept(params: any) {
  const baseUrl = "/invoices/accept-estimate";
  return api.createWithFile(`${baseUrl}`, params);
}

export { estimatesList, estimatesListAll, estimatesFetch, estimateAccept };
