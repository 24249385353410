// @flow
import { all, fork, put, takeEvery, call } from "redux-saga/effects";

import {
  paymentGatewayList as paymentGatewayListApi,
  paymentGatewayApp as paymentGatewayAppApi,
  paymentSourceList as paymentSourceListApi,
  paymentsSourceFetch as paymentsSourceFetchApi,
  addCard as addCardApi,
  deleteCard as deleteCardApi,
  defaultCard as defaultCardApi,
  stripeMethodIdentity as stripeMethodIdentityApi,
  braintreeClientToken as braintreeClientTokenApi,
  cyberCreatePublicKey as cyberCreatePublicKeyApi,
  saveBankAccount as saveBankAccountApi,
  verifyBankAccount as verifyBankAccountApi,
  stripeLinkDonationIdentity as stripeLinkDonationIdentityApi,
  stripeInvoiceIdentity as stripeInvoiceIdentityApi,
  payPalCreateToken as payPalCreateTokenApi,
} from "../../helpers";

import { paymentApiResponseSuccess, paymentApiResponseError } from "./actions";
import { PaymentActionTypes } from "./constants";

/**
 * Login the user
 * @param {*} payload - username and password
 */

function* paymentGatewayList({ payload: {} }) {
  try {
    const response = yield call(paymentGatewayListApi, {});
    if (response?.status) {
      const payments = response.data?.data;
      // NOTE - You can change this according to response format from your api

      yield put(
        paymentApiResponseSuccess(
          PaymentActionTypes.PAYMENT_GATEWAY_ALL,
          payments
        )
      );
    } else {
      yield put(
        paymentApiResponseError(
          PaymentActionTypes.PAYMENT_GATEWAY_ALL,
          "Unable to load payments"
        )
      );
    }
  } catch (error) {
    //console.log(error);
    yield put(
      paymentApiResponseError(PaymentActionTypes.PAYMENT_GATEWAY_ALL, error)
    );
  }
}

function* paymentGatewayApp({ payload: { name } }) {
  try {
    const response = yield call(paymentGatewayAppApi, { name });
    //"response gateway"+ response);
    if (response?.status) {
      const payments = response.data?.data;
      // NOTE - You can change this according to response format from your api

      yield put(
        paymentApiResponseSuccess(
          PaymentActionTypes.PAYMENT_GATEWAY_APP,
          payments
        )
      );
    } else {
      yield put(
        paymentApiResponseError(
          PaymentActionTypes.PAYMENT_GATEWAY_APP,
          "Unable to load payments"
        )
      );
    }
  } catch (error) {
    //console.log(error);
    // alert("error gateway"+ error);
    yield put(
      paymentApiResponseError(PaymentActionTypes.PAYMENT_GATEWAY_APP, error)
    );
  }
}

function* paymentSourceList({ payload: {} }) {
  try {
    // alert("sending data");
    const response = yield call(paymentSourceListApi, {});

    if (response?.status) {
      const payments = response.data?.data;
      //  alert("response"+JSON.stringify(payments));
      // NOTE - You can change this according to response format from your api
      if (payments?.length > 0) {
        yield put(
          paymentApiResponseSuccess(PaymentActionTypes.SOURCES_ALL, payments)
        );
      } else {
        yield put(
          paymentApiResponseError(
            PaymentActionTypes.SOURCES_ALL,
            "Unable to load payments"
          )
        );
      }
    } else {
      yield put(
        paymentApiResponseError(
          PaymentActionTypes.SOURCES_ALL,
          "Unable to load payments"
        )
      );
    }
  } catch (error) {
    //console.log(error);
    yield put(paymentApiResponseError(PaymentActionTypes.SOURCES_ALL, error));
  }
}

function* addCard({
  payload: {
    id,
    methodId,
    token,
    address,
    city,
    state,
    zipCode,
    country,
    defaultSource,
    metadata,
  },
}) {
  try {
    // alert("sending data");
    const response = yield call(addCardApi, {
      id,
      methodId,
      token,
      address,
      city,
      state,
      zipCode,
      country,
      defaultSource,
      metadata,
    });
    // alert(JSON.stringify(response))
    if (response?.data !== undefined) {
      if (response?.data?.status) {
        //  alert(JSON.stringify(response))
        const payments = response?.data;
        //  alert("response"+JSON.stringify(payments));
        // NOTE - You can change this according to response format from your api

        yield put(
          paymentApiResponseSuccess(PaymentActionTypes.ADD_CARD, payments)
        );
      } else {
        // alert(JSON.stringify(response.data.message))

        yield put(
          paymentApiResponseError(
            PaymentActionTypes.ADD_CARD,
            response?.data?.message
          )
        );
      }
    } else {
      yield put(
        paymentApiResponseError(
          PaymentActionTypes.ADD_CARD,
          "Error! Unable to add card, please try again with different options."
        )
      );
    }
  } catch {
    //console.log(error);
    //alert(error);
    yield put(
      paymentApiResponseError(
        PaymentActionTypes.ADD_CARD,
        "Error! Unable to add card, please try again with different options."
      )
    );
  }
}

function* saveBankAccount({
  payload: {
    id,
    methodId,
    token,
    nameOnAccount,
    accountType,
    accountHolderType,
    routingNumber,
    accountNumber,
    defaultSource,
  },
}) {
  try {
    // alert("sending data");
    const response = yield call(saveBankAccountApi, {
      id,
      methodId,
      token,
      nameOnAccount,
      accountType,
      accountHolderType,
      routingNumber,
      accountNumber,
      defaultSource,
    });

    if (response.data?.status) {
      //  alert(JSON.stringify(response))
      const payments = response?.data;
      //  alert("response"+JSON.stringify(payments));
      // NOTE - You can change this according to response format from your api

      yield put(
        paymentApiResponseSuccess(PaymentActionTypes.ADD_CARD, payments)
      );
    } else {
      //alert(JSON.stringify(response.data.message))

      yield put(
        paymentApiResponseError(
          PaymentActionTypes.ADD_CARD,
          response.data.message
        )
      );
    }
  } catch (error) {
    //console.log(error);
    yield put(paymentApiResponseError(PaymentActionTypes.ADD_CARD, error));
  }
}

function* verifyBankAccount({ payload: { id, sourceId, deposit1, deposit2 } }) {
  try {
    //alert("sending data"+id+sourceId+amount1+amount2);
    const response = yield call(verifyBankAccountApi, {
      id,
      sourceId,
      deposit1,
      deposit2,
    });
    //alert(JSON.stringify(response))
    if (response.data?.status) {
      const payments = response?.data;
      //  alert("response"+JSON.stringify(payments));
      // NOTE - You can change this according to response format from your api

      yield put(
        paymentApiResponseSuccess(
          PaymentActionTypes.VERIFY_BANK_ACCOUNT,
          payments
        )
      );
    } else {
      //alert(JSON.stringify(response.data.message))

      yield put(
        paymentApiResponseError(
          PaymentActionTypes.VERIFY_BANK_ACCOUNT,
          response.data.message
        )
      );
    }
  } catch (error) {
    //console.log(error);
    //alert(JSON.stringify(error))
    yield put(
      paymentApiResponseError(PaymentActionTypes.VERIFY_BANK_ACCOUNT, error)
    );
  }
}

function* defaultCard({ payload: { id, sourceId } }) {
  try {
    // alert(id+"sending data"+sourceId);
    const response = yield call(defaultCardApi, { id, sourceId });

    if (response?.status) {
      const payments = response.data;
      //  alert("response"+JSON.stringify(payments));
      // NOTE - You can change this according to response format from your api

      yield put(
        paymentApiResponseSuccess(PaymentActionTypes.DEFAULT_CARD, payments)
      );
    } else {
      yield put(
        paymentApiResponseError(
          PaymentActionTypes.DEFAULT_CARD,
          "Unable to load mark default card"
        )
      );
    }
  } catch (error) {
    //console.log(error);
    yield put(paymentApiResponseError(PaymentActionTypes.DEFAULT_CARD, error));
  }
}

function* deleteCard({ payload: { id, sourceId } }) {
  try {
    // alert("sending data");
    const response = yield call(deleteCardApi, { id, sourceId });

    if (response.data?.status) {
      const payments = response.data;
      //  alert("response"+JSON.stringify(payments));
      // NOTE - You can change this according to response format from your api

      yield put(
        paymentApiResponseSuccess(PaymentActionTypes.DELETE_CARD, payments)
      );
    } else {
      yield put(
        paymentApiResponseError(
          PaymentActionTypes.DELETE_CARD,
          response.data.message
        )
      );
    }
  } catch (error) {
    //console.log(error);
    yield put(paymentApiResponseError(PaymentActionTypes.DELETE_CARD, error));
  }
}

function* stripePaymentMethodIdentity({ payload: { id, methodId } }) {
  try {
    // alert("sending data");
    const response = yield call(stripeMethodIdentityApi, { id, methodId });
    //alert("response" + JSON.stringify(response));
    if (response?.status) {
      const payments = response.data;
      //  alert("response"+JSON.stringify(payments));
      // NOTE - You can change this according to response format from your api

      yield put(
        paymentApiResponseSuccess(
          PaymentActionTypes.CREATE_PAYMENT_SOURCE_IDENTIFY,
          payments
        )
      );
    } else {
      yield put(
        paymentApiResponseError(
          PaymentActionTypes.CREATE_PAYMENT_SOURCE_IDENTIFY,
          "Unable to add card, please use correct card details"
        )
      );
    }
  } catch {
    // alert("error" + JSON.stringify(error));

    yield put(
      paymentApiResponseError(
        PaymentActionTypes.CREATE_PAYMENT_SOURCE_IDENTIFY,
        "Unable to add card, please use correct card details"
      )
    );
  }
}

function* stripeLinkDonationIdentity({
  payload: {
    paymentGateway,
    id,
    type,
    quantity,
    amount,
    phoneCode,
    phoneNumber,
    recurringDonation,
    billingAddressDiff,
    billingAddress,
    billingCity,
    billingState,
    billingZipCode,
    billingCountry,
    firstName,
    lastName,
    email,
    address,
    city,
    state,
    zipCode,
    country,
    fullName,
    anonymous,
    shippingAddress,
    shippingCity,
    shippingState,
    shippingZipCode,
    shippingCountry,
  },
}) {
  try {
    // alert("sending data");
    const response = yield call(stripeLinkDonationIdentityApi, {
      paymentGateway,
      id,
      type,
      quantity,
      amount,
      phoneCode,
      phoneNumber,
      recurringDonation,
      billingAddressDiff,
      billingAddress,
      billingCity,
      billingState,
      billingZipCode,
      billingCountry,
      firstName,
      lastName,
      email,
      address,
      city,
      state,
      zipCode,
      country,
      fullName,
      anonymous,
      shippingAddress,
      shippingCity,
      shippingState,
      shippingZipCode,
      shippingCountry,
    });

    if (response?.status) {
      const payments = response.data;
      //  alert("response"+JSON.stringify(payments));
      // NOTE - You can change this according to response format from your api

      yield put(
        paymentApiResponseSuccess(
          PaymentActionTypes.CREATE_LINK_DONATION_IDENTIFY,
          payments
        )
      );
    } else {
      yield put(
        paymentApiResponseError(
          PaymentActionTypes.CREATE_LINK_DONATION_IDENTIFY,
          "Sorry! Unable to create payment identity"
        )
      );
    }
  } catch (error) {
    //console.log(error);
    yield put(
      paymentApiResponseError(
        PaymentActionTypes.CREATE_LINK_DONATION_IDENTIFY,
        error
      )
    );
  }
}

function* stripeInvocieIdentity({
  payload: { id, fullName, address, city, state, zipCode, country },
}) {
  try {
    // alert("sending data");
    const response = yield call(stripeInvoiceIdentityApi, {
      id,
      fullName,
      address,
      city,
      state,
      zipCode,
      country,
    });

    if (response?.status) {
      const payments = response.data;
      //  alert("response"+JSON.stringify(payments));
      // NOTE - You can change this according to response format from your api

      yield put(
        paymentApiResponseSuccess(
          PaymentActionTypes.CREATE_INVOICE_IDENTIFY,
          payments
        )
      );
    } else {
      yield put(
        paymentApiResponseError(
          PaymentActionTypes.CREATE_INVOICE_IDENTIFY,
          "Sorry! Unable to create payment identity"
        )
      );
    }
  } catch (error) {
    //console.log(error);
    yield put(
      paymentApiResponseError(PaymentActionTypes.CREATE_INVOICE_IDENTIFY, error)
    );
  }
}

function* braintreeCreateToken({ payload: { id } }) {
  try {
    // alert("sending data");
    const response = yield call(braintreeClientTokenApi, { id });

    if (response?.status) {
      const payments = response.data;
      //  alert("response"+JSON.stringify(payments));
      // NOTE - You can change this according to response format from your api

      yield put(
        paymentApiResponseSuccess(
          PaymentActionTypes.CREATE_BRAINTREE_TOKEN,
          payments
        )
      );
    } else {
      yield put(
        paymentApiResponseError(
          PaymentActionTypes.CREATE_BRAINTREE_TOKEN,
          "Unable to get braintree token"
        )
      );
    }
  } catch (error) {
    //console.log(error);
    yield put(
      paymentApiResponseError(PaymentActionTypes.CREATE_BRAINTREE_TOKEN, error)
    );
  }
}

function* payPalCreateToken({ payload: { id } }) {
  try {
    // alert("sending data");
    const response = yield call(payPalCreateTokenApi, { id });

    if (response?.status) {
      const payments = response.data;
      //  alert("response"+JSON.stringify(payments));
      // NOTE - You can change this according to response format from your api

      yield put(
        paymentApiResponseSuccess(
          PaymentActionTypes.CREATE_PAYPAL_CLIENT_TOKEN,
          payments
        )
      );
    } else {
      yield put(
        paymentApiResponseError(
          PaymentActionTypes.CREATE_PAYPAL_CLIENT_TOKEN,
          "Unable to get paypal token"
        )
      );
    }
  } catch (error) {
    //console.log(error);
    yield put(
      paymentApiResponseError(
        PaymentActionTypes.CREATE_PAYPAL_CLIENT_TOKEN,
        error
      )
    );
  }
}

function* cyberCreatePublicKey({ payload: { id, host } }) {
  try {
    // alert("sending data");
    const response = yield call(cyberCreatePublicKeyApi, { id, host });

    if (response?.status) {
      const payments = response.data;
      //  alert("response"+JSON.stringify(payments));
      // NOTE - You can change this according to response format from your api

      yield put(
        paymentApiResponseSuccess(
          PaymentActionTypes.CREATE_CYBER_PUBLIC_KEY,
          payments
        )
      );
    } else {
      yield put(
        paymentApiResponseError(
          PaymentActionTypes.CREATE_CYBER_PUBLIC_KEY,
          "Unable to get braintree token"
        )
      );
    }
  } catch (error) {
    //console.log(error);
    yield put(
      paymentApiResponseError(PaymentActionTypes.CREATE_CYBER_PUBLIC_KEY, error)
    );
  }
}

function* paymentsSourceFetch({ payload: { source_id } }) {
  try {
    const response = yield call(paymentsSourceFetchApi, { source_id });
    if (response?.status) {
      const payment = response.data?.data;
      // NOTE - You can change this according to response format from your api
      // alert(JSON.stringify(payment));

      yield put(
        paymentApiResponseSuccess(PaymentActionTypes.SOURCES_FETCH, payment)
      );
    } else {
      yield put(
        paymentApiResponseError(
          PaymentActionTypes.SOURCES_FETCH,
          "Unable to load payment"
        )
      );
    }
  } catch (error) {
    //console.log(error);
    yield put(paymentApiResponseError(PaymentActionTypes.SOURCES_FETCH, error));
  }
}

/**
 * Logout the user
 */
export function* watchPaymentGatewayList(): any {
  yield takeEvery(PaymentActionTypes.PAYMENT_GATEWAY_ALL, paymentGatewayList);
}

export function* watchPaymentGatewayApp(): any {
  yield takeEvery(PaymentActionTypes.PAYMENT_GATEWAY_APP, paymentGatewayApp);
}

export function* watchPaymentSourceList(): any {
  yield takeEvery(PaymentActionTypes.SOURCES_ALL, paymentSourceList);
}

export function* watchAddCard(): any {
  yield takeEvery(PaymentActionTypes.ADD_CARD, addCard);
}

export function* watchDefaultCard(): any {
  yield takeEvery(PaymentActionTypes.DEFAULT_CARD, defaultCard);
}

export function* watchDeleteCard(): any {
  yield takeEvery(PaymentActionTypes.DELETE_CARD, deleteCard);
}

export function* watchPaymentsSourceFetch(): any {
  yield takeEvery(PaymentActionTypes.SOURCES_FETCH, paymentsSourceFetch);
}

export function* watchStripePaymentMethodIdentity(): any {
  yield takeEvery(
    PaymentActionTypes.CREATE_PAYMENT_SOURCE_IDENTIFY,
    stripePaymentMethodIdentity
  );
}

export function* watchBraintreeCreateToken(): any {
  yield takeEvery(
    PaymentActionTypes.CREATE_BRAINTREE_TOKEN,
    braintreeCreateToken
  );
}

export function* watchSaveBankAccount(): any {
  yield takeEvery(PaymentActionTypes.SAVE_BANK_ACCOUNT, saveBankAccount);
}

export function* watchCyberCreateToken(): any {
  yield takeEvery(
    PaymentActionTypes.CREATE_CYBER_PUBLIC_KEY,
    cyberCreatePublicKey
  );
}

export function* watchStripeLinkDonationIdentity(): any {
  yield takeEvery(
    PaymentActionTypes.CREATE_LINK_DONATION_IDENTIFY,
    stripeLinkDonationIdentity
  );
}

export function* watchVerifyBankAccount(): any {
  yield takeEvery(PaymentActionTypes.VERIFY_BANK_ACCOUNT, verifyBankAccount);
}

export function* watchstripeInvocieIdentity(): any {
  yield takeEvery(
    PaymentActionTypes.CREATE_INVOICE_IDENTIFY,
    stripeInvocieIdentity
  );
}

export function* watchpayPalCreateToken(): any {
  yield takeEvery(
    PaymentActionTypes.CREATE_PAYPAL_CLIENT_TOKEN,
    payPalCreateToken
  );
}

export function* watchpayPalCreateOrder(): any {
  yield takeEvery(PaymentActionTypes.CREATE_PAYPAL_ORDER, payPalCreateToken);
}

function* paymentSaga(): any {
  yield all([
    fork(watchPaymentGatewayList),
    fork(watchPaymentGatewayApp),
    fork(watchPaymentSourceList),
    fork(watchPaymentsSourceFetch),
    fork(watchAddCard),
    fork(watchDefaultCard),
    fork(watchDeleteCard),
    fork(watchStripePaymentMethodIdentity),
    fork(watchBraintreeCreateToken),
    fork(watchCyberCreateToken),
    fork(watchSaveBankAccount),
    fork(watchStripeLinkDonationIdentity),
    fork(watchVerifyBankAccount),
    fork(watchstripeInvocieIdentity),
    fork(watchpayPalCreateToken),
    fork(watchpayPalCreateOrder),
  ]);
}

export default paymentSaga;
