export const InvoiceActionTypes = {
  API_RESPONSE_SUCCESS: "@@invoice/API_RESPONSE_SUCCESS",
  API_RESPONSE_ERROR: "@@invoice/API_RESPONSE_ERROR",

  INVOICES: "@@invoice/INVOICES",
  INVOICES_ALL: "@@invoice/INVOICES_ALL",
  INVOICES_FETCH: "@@invoice/INVOICES_FETCH",
  INVOICES_DUE_AMOUNT: "@@invoice/INVOICES_DUE_AMOUNT",
  PAY_INVOICE_WITH_CLIENT_SOURCE: "@@invoice/PAY_INVOICE_WITH_CLIENT_SOURCE",
  PAY_INVOICE_OFFLINE: "@@invoice/PAY_INVOICE_OFFLINE",
  PAY_INVOICE_DIRECTLY: "@@invoice/PAY_INVOICE_DIRECTLY",
  PAY_INVOICE_PAYPAL: "@@invoice/PAY_INVOICE_PAYPAL",
  MARK_INVOICE_VIEWED: "@@invoice/MARK_INVOICE_VIEWED",
  PAY_INVOICE_OPEN_NODE: "@@invoice/PAY_INVOICE_OPEN_NODE",
  RESET: "@@invoice/RESET",
};
