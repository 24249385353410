// @flow
import { APICore } from "./apiCore";

const api = new APICore();

// account
function donationsList(params: any): any {
  const baseUrl = "/donations";
  return api.getEmpty(`${baseUrl}`, params);
}

function donationsListAll(): any {
  const baseUrl = "/donations/all";
  //console.log("------------->"+baseUrl);
  return api.get(`${baseUrl}`, {});
}

function subscriptioinListAll(): any {
  const baseUrl = "/subscriptions/all";
  //console.log("------------->"+baseUrl);
  return api.get(`${baseUrl}`, {});
}

function donationsFetch(donation_id) {
  //console.log("safasdfdasf"+ JSON.stringify(donation_id.donation_id));
  const baseUrl = "/donation-forms/" + donation_id.donation_id;
  return api.get(`${baseUrl}`, {});
}
function paymentLink(id) {
  //console.log("safasdfdasf"+ JSON.stringify(id.id));
  const baseUrl = "/payment-links/" + id.id;
  return api.get(`${baseUrl}`, {});
}

function payPaymentLinkStripe(params: any): any {
  const baseUrl = "/payments/stripe/pay-link";
  return api.createWithFile(`${baseUrl}`, params);
}
function payPaymentLinkBrainTree(params: any): any {
  const baseUrl = "/payments/braintree/pay-link";
  return api.createWithFile(`${baseUrl}`, params);
}

function payPaymentLinkAuthorize(params: any): any {
  const baseUrl = "/payments/authorizenet/pay-link";
  return api.createWithFile(`${baseUrl}`, params);
}
function payPaymentLinkCybersource(params: any): any {
  const baseUrl = "/payments/cybersource/pay-link";
  return api.createWithFile(`${baseUrl}`, params);
}
function payPaymentLinkPayPal(params: any): any {
  const baseUrl = "/payments/paypal/pay-link";
  return api.createWithFile(`${baseUrl}`, params);
}

function payPaymentLinkNmi(params: any): any {
  const baseUrl = "/payments/nmi/pay-link";
  return api.createWithFile(`${baseUrl}`, params);
}

function donationAcceptStripe(params: any) {
  const baseUrl = "/payments/stripe/pay-donation";
  return api.createWithFile(`${baseUrl}`, params);
}

function donationAcceptPaypal(params: any) {
  const baseUrl = "/payments/paypal/pay-donation";
  return api.createWithFile(`${baseUrl}`, params);
}

function countDonation(params: any): any {
  const baseUrl = "/stats/donations/count-visitor";
  return api.createWithFile(`${baseUrl}`, params);
}

function countPaymentLink(params: any) {
  const baseUrl = "/stats/links/count-visitor";
  return api.createWithFile(`${baseUrl}`, params);
}

function donationAcceptBrainTree(params: any): any {
  const baseUrl = "/payments/braintree/pay-donation";
  return api.createWithFile(`${baseUrl}`, params);
}

function donationAcceptNmi(params: any): any {
  const baseUrl = "/payments/nmi/pay-donation";
  return api.createWithFile(`${baseUrl}`, params);
}
function paymentLinkCancel(params: any): any {
  // alert(JSON.stringify(params));
  const baseUrl = "/links/cancel";
  return api.createWithFile(`${baseUrl}`, params);
}

function donationCancel(params: any): any {
  const baseUrl = "/donations/cancel";
  return api.createWithFile(`${baseUrl}`, params);
}
function donationAcceptAuthorize(params: any): any {
  const baseUrl = "/payments/authorizenet/pay-donation";
  return api.createWithFile(`${baseUrl}`, params);
}
function donationAcceptCybersource(params: any): any {
  const baseUrl = "/payments/cybersource/pay-donation";
  return api.createWithFile(`${baseUrl}`, params);
}

export {
  paymentLinkCancel,
  donationCancel,
  payPaymentLinkStripe,
  payPaymentLinkBrainTree,
  payPaymentLinkCybersource,
  payPaymentLinkAuthorize,
  paymentLink,
  subscriptioinListAll,
  donationsList,
  donationsListAll,
  donationsFetch,
  donationAcceptStripe,
  donationAcceptBrainTree,
  donationAcceptAuthorize,
  donationAcceptCybersource,
  donationAcceptNmi,
  countDonation,
  countPaymentLink,
  payPaymentLinkPayPal,
  donationAcceptPaypal,
  payPaymentLinkNmi,
};
