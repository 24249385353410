export const DonationActionTypes = {
  API_RESPONSE_SUCCESS: "@@donation/API_RESPONSE_SUCCESS",
  API_RESPONSE_ERROR: "@@donation/API_RESPONSE_ERROR",

  DONATIONS: "@@donation/DONATIONS",
  DONATIONS_ALL: "@@donation/DONATIONS_ALL",
  DONATIONS_FETCH: "@@donation/DONATIONS_FETCH",
  ACCEPT_DONATION: "@@donation/ACCEPT_DONATION",
  // payment link
  SUBSCRIPTION_ALL: "@@donation/SUBSCRIPTION_ALL",
  PAYMENT_LINK: "@@donation/PAYMENT_LINK",
  PAY_PAYMENT_LINK: "@@donation/PAY_PAYMENT_LINK",
  COUNT_PAYMENT_LINK: "@@donation/COUNT_PAYMENT_LINK",
  COUNT_DONATION: "@@donation/COUNT_DONATION",

  CANCEL_PAYMENT_LINK: "@@donation/CANCEL_PAYMENT_LINK",
  CANCEL_DONATION: "@@donation/CANCEL_DONATION",

  RESET: "@@donation/RESET",
};
