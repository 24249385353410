// @flow
import { InvoiceActionTypes } from "./constants";

type InvoiceAction = { type: string, payload: {} | string };

// common success
export const invoiceApiResponseSuccess = (
  actionType: string,
  data: any
): InvoiceAction => ({
  type: InvoiceActionTypes.API_RESPONSE_SUCCESS,
  payload: { actionType, data },
});
// common error
export const invoiceApiResponseError = (
  actionType: string,
  error: string
): InvoiceAction => ({
  type: InvoiceActionTypes.API_RESPONSE_ERROR,
  payload: { actionType, error },
});

export const invoiceList = (): InvoiceAction => ({
  type: InvoiceActionTypes.INVOICES,
  payload: {},
});

export const invoiceListAll = (): InvoiceAction => ({
  type: InvoiceActionTypes.INVOICES_ALL,
  payload: {},
});

export const invoiceFetch = (invoice_id): InvoiceAction => ({
  type: InvoiceActionTypes.INVOICES_FETCH,
  payload: { invoice_id },
});

export const invoiceTotalDueAmount = (id): InvoiceAction => ({
  type: InvoiceActionTypes.INVOICES_DUE_AMOUNT,
  payload: { id },
});

export const invoiceMarkViewed = (id): InvoiceAction => ({
  type: InvoiceActionTypes.MARK_INVOICE_VIEWED,
  payload: { id },
});

export const payInvoiceWithClientSource = (
  id,
  sourceId,
  fullName,
  address,
  city,
  state,
  zipCode,
  country,
  paymentMode
): InvoiceAction => ({
  type: InvoiceActionTypes.PAY_INVOICE_WITH_CLIENT_SOURCE,
  payload: {
    id,
    sourceId,
    fullName,
    address,
    city,
    state,
    zipCode,
    country,
    paymentMode,
  },
});

export const payInvoiceDirectly = (
  paymentGateway,
  id,
  nonce,
  data,
  token,
  orderId,
  fullName,
  address,
  city,
  state,
  zipCode,
  country,
  billingAddress,
  billingCity,
  billingState,
  billingZipCode,
  billingCountry,
  paymentMode,
  saveCard,
  metadata = ""
): InvoiceAction => ({
  type: InvoiceActionTypes.PAY_INVOICE_DIRECTLY,
  payload: {
    paymentGateway,
    id,
    nonce,
    data,
    token,
    orderId,
    fullName,
    address,
    city,
    state,
    zipCode,
    country,
    billingAddress,
    billingCity,
    billingState,
    billingZipCode,
    billingCountry,
    paymentMode,
    saveCard,
    metadata,
  },
});

export const payInvoicePayPal = (
  id,
  orderId,
  fullName,
  address,
  city,
  state,
  zipCode,
  country,
  source = ""
): InvoiceAction => ({
  type: InvoiceActionTypes.PAY_INVOICE_PAYPAL,
  payload: {
    id,
    orderId,
    fullName,
    address,
    city,
    state,
    zipCode,
    country,
    source,
  },
});

export const payInvoiceOpenNode = (
  id,
  fullName,
  address,
  city,
  state,
  zipCode,
  country
): InvoiceAction => ({
  type: InvoiceActionTypes.PAY_INVOICE_OPEN_NODE,
  payload: {
    id,
    fullName,
    address,
    city,
    state,
    zipCode,
    country,
  },
});

export const payInvoiceOffline = (
  id,
  expectedPaymentDate,
  expectedPaymentMethod,
  expectedPaymentReference
): InvoiceAction => ({
  type: InvoiceActionTypes.PAY_INVOICE_OFFLINE,
  payload: {
    id,
    expectedPaymentDate,
    expectedPaymentMethod,
    expectedPaymentReference,
  },
});

export const resetInvoice = (): InvoiceAction => ({
  type: InvoiceActionTypes.RESET,
  payload: {},
});
