import config from "../config";
import { getSymbolFromCurrency } from "./helperfunctions";
import { APICore } from "./api/apiCore";
const api = new APICore();
const { FormatMoney } = require("format-money-js");

const fm = new FormatMoney({
  decimals: 2,
});

const getLogoURL = () => {
  // NOTE - You can fetch from server and return here as well

  if (api.getAccount()) {
    if (api.getAccount()?.preferences.brand_logo) {
      return config.APP_IMAGE_URI + api.getAccount()?.preferences.brand_logo;
    } else {
      return undefined;
    }
  }

  return undefined;
};

const getBrandIconURL = () => {
  // NOTE - You can fetch from server and return here as well

  if (
    api.getAccount()?.preferences.use_brand_logo &&
    api.getAccount()?.preferences.brand_logo
  ) {
    return config.APP_IMAGE_URI + api.getAccount()?.preferences.brand_logo;
  } else if (api?.getAccount()?.preferences.brand_icon) {
    return config.APP_IMAGE_URI + api.getAccount()?.preferences.brand_icon;
  }

  return "";
};

const getCurrencySigntInfo = () => {
  let symbol = "$";

  if (api.getAccount()) {
    const billingInfo = {};
    const account = api.getAccount();

    if (!account) {
      return symbol;
    } else {
      const curr = account?.preferences?.currency;
      symbol = curr ? getSymbolFromCurrency(curr) : "$";
      symbol = symbol || "$";
    }

    return symbol;
  }
};

const getCurrencySign = () => {
  let symbol = "usd";

  if (api.getAccount()) {
    const billingInfo = {};
    const account = api.getAccount();

    if (!account) {
      return symbol;
    } else {
      const curr = account?.preferences?.currency;

      symbol = curr || symbol;
    }

    return symbol;
  }
};

const roundAmount = (amount) => {
  // alert(amount);
  const number = fm.from(amount, { symbol: getCurrencySigntInfo() });

  return number || "";
};

const roundAmountNoCurrency = (amount, ds = 2) => {
  const number = fm.from(amount, { decimals: ds });
  return number;
};

const isPaymentGatewaySetup = () => {
  if (api.getAccount()) {
    const account = api.getAccount();

    if (
      account &&
      (account.cardGateway ||
        account.achGateway ||
        account.offline_payments_meta ||
        account.paypal_enabled)
    ) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

const isCardPaymentGatewaySetup = () => {
  const account = api.getAccount();
  if (account) {
    if (account && account.cardGateway) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

const isBetweenRange = (type, ammount) => {
  const account = api.getAccount();
  if (account) {
    switch (type) {
      case "card":
        if (
          (!account.card_min_amount || account.card_min_amount <= ammount) &&
          (!account.card_max_amount || account.card_max_amount >= ammount)
        ) {
          return true;
        }
        break;
      case "ach":
        if (
          (!account.ach_min_amount || account.ach_min_amount <= ammount) &&
          (!account.ach_max_amount || account.ach_max_amount >= ammount)
        ) {
          return true;
        }
        break;
      case "paypal":
        if (
          (!account.paypal_min_amount ||
            account.paypal_min_amount <= ammount) &&
          (!account.paypal_max_amount || account.paypal_max_amount >= ammount)
        ) {
          return true;
        }
        break;
      case "crypto":
        if (
          (!account.crypto_min_amount ||
            account.crypto_min_amount <= ammount) &&
          (!account.crypto_max_amount || account.crypto_max_amount >= ammount)
        ) {
          return true;
        }
        break;
      default:
        return false;
    }
  } else {
    return false;
  }
};

const isAchCardPaymentGatewaySetup = () => {
  if (api.getAccount()) {
    const account = api.getAccount();

    if (account && (account.cardGateway || account.achGateway)) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

const isAchPaymentGatewaySetup = () => {
  if (api.getAccount()) {
    const account = api.getAccount();
    if (account && account.achGateway) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

const isCryptoGatewaySetup = () => {
  if (api.getAccount()) {
    const account = api.getAccount();

    if (account && account.cryptoGateway) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

const isGooglePayEnabled = () => {
  if (api.getAccount()) {
    const account = api.getAccount();

    if (account && account.cardGateway && account.cardGateway.google_pay) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

const isApplePayEnabled = () => {
  if (api.getAccount()) {
    const account = api.getAccount();

    if (account && account.cardGateway && account.cardGateway.apple_pay) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

const isApplePayDomainVerified = () => {
  if (api.getAccount()) {
    const account = api.getAccount();

    if (account && account.apple_pay_domain_verified) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

const isPayPalEnabled = () => {
  if (api.getAccount()) {
    const account = api.getAccount();

    if (account && account.paypal_enabled) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

const isPayPalVenmoActive = () => {
  if (api.getAccount()) {
    const account = api.getAccount();

    if (account && account.paypal_venmo_active) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

const isPayPalCardActive = () => {
  if (api.getAccount()) {
    const account = api.getAccount();

    if (account && account.paypal_card_active) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

const isPayPalPayLaterActive = () => {
  if (api.getAccount()) {
    const account = api.getAccount();

    if (account && account.paypal_paylater_active) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

const getPayPalMerchantId = () => {
  if (api.getAccount()) {
    const account = api.getAccount();

    if (account && account.paypal_enabled) {
      return account.paypal_merchant_id;
    } else {
      return null;
    }
  } else {
    return null;
  }
};

const isModifyBilling = () => {
  if (api.getAccount()) {
    const account = api.getAccount();

    if (account && account.preferences.modify_billing_info) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

const isShowItemDescription = () => {
  if (api.getAccount()) {
    const account = api.getAccount();

    if (account && account.preferences.show_item_description) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

const getPayPalMerchant = () => {
  if (api.getAccount()) {
    const account = api.getAccount();

    if (account && account.paypal_enabled && account.paypal_merchant_id) {
      return account.paypal_merchant_id;
    } else {
      return null;
    }
  } else {
    return null;
  }
};

const getPaymentGatewayCard = () => {
  if (api.getAccount()) {
    const cardGateway = {};
    const account = api.getAccount();

    if (!account && !account.cardGateway) {
      return undefined;
    } else {
      return account.cardGateway;
    }
  } else {
    return undefined;
  }
};

const getOfflinePaymentMeta = () => {
  if (api.getAccount()) {
    const account = api.getAccount();

    if (!account && !account.offline_payments_meta) {
      return undefined;
    } else {
      return account.offline_payments_meta;
    }
  } else {
    return undefined;
  }
};

const getFeeInfo = (type, total_amount) => {
  if (api.getAccount()) {
    const feeInfo = {};
    const account = api.getAccount();

    if (!account || !account.fee_enabled) {
      return undefined;
    } else {
      let exist = false;

      if (account.fee_active_modules) {
        exist = account?.fee_active_modules?.includes(type);
      } else {
        exist = true;
      }
      if (exist) {
        feeInfo.fee_type = account.fee_type;
        feeInfo.fee_title = account.fee_title || "Fee";
        if (account.fee_type == "percent") {
          feeInfo.fee_ammount = (total_amount * account.fee_amount) / 100;
          feeInfo.fee_percent = account.fee_amount;
        } else {
          feeInfo.fee_ammount = account.fee_amount;
        }
      } else {
        return undefined;
      }
    }

    return feeInfo;
  }
};

const getOrgName = () => {
  // NOTE - You can fetch from server and return here as well
  if (api.getAccount()?.business_name) {
    return api.getAccount()?.business_name;
  }
  return "";
};

const setPageTitle = (title) => {
  // NOTE - You can fetch from server and return here as well
  if (api.getAccount()?.business_name) {
    document.title = title + " - " + getOrgName();
  } else {
    document.title = title;
  }
};

const getUserFullName = () => {
  // NOTE - You can fetch from server and return here as well
  if (api.getLoggedInUser()?.data) {
    const { first_name, last_name } = api.getLoggedInUser()?.data;
    return `${first_name} ${last_name || ""}`;
  }
  return "";
};

const getUserCompany = () => {
  // NOTE - You can fetch from server and return here as well
  if (api.getLoggedInUser()?.data) {
    const { company } = api.getLoggedInUser()?.data;
    return `${company || ""}`;
  }
  return "";
};

const getUserEmail = () => {
  if (api.getLoggedInUser()?.data) {
    const { email } = api.getLoggedInUser()?.data;
    return `${email || ""}`;
  }
  return "";
};

const getAccountName = () => {
  // NOTE - You can fetch from server and return here as well
  if (api.getAccount()) {
    return api.first_name + " " + api.getAccount()?.last_name;
  }
  return "";
};

const setPayPalOrder = (payPalOrder) => {
  // NOTE - You can fetch from server and return here as well

  api.setPayPalOrder(payPalOrder);
};

const getPayPalOrder = () => {
  // NOTE - You can fetch from server and return here as well

  return api.getPayPalOrder();
};

const getIsAccountExist = () => {
  // NOTE - You can fetch from server and return here as well
  if (api.getAccount()) {
    return true;
  }
  return false;
};

const getBraintreeConfig = (token, amount, type = "invoice") => {
  const cardGateway = getPaymentGatewayCard();

  if (cardGateway?.vendor_name === "braintree") {
    let config = {
      authorization: token,
      paymentOptionPriority: [
        "card",
        "applePay",
        "googlePay",
        "paypal",
        "paypalCredit",
        "venmo",
      ],
    };

    if (cardGateway.paypal) {
      config.paypal = { flow: "vault" };
    }

    if (cardGateway.venmo) {
      config.venmo = { allowNewBrowserTab: true };
    }

    if (cardGateway.google_pay) {
      config.googlePay = {
        googlePayVersion: 2,
        merchantId: cardGateway.google_merchant_id,
        transactionInfo: {
          totalPriceLabel: "Total",
          totalPriceStatus: "FINAL",
          totalPrice: roundAmountNoCurrency(amount),
          currencyCode: getCurrencySign()?.toUpperCase() || "USD",
        },
        allowedPaymentMethods: [
          {
            type: "CARD",
            parameters: {
              billingAddressRequired: true,
              billingAddressParameters: {
                format: "FULL",
                phoneNumberRequired: type === "donation",
              },
            },
          },
        ],
        shippingAddressRequired: true,
        emailRequired: true,
      };
    }

    if (cardGateway.apple_pay) {
      config.applePay = {
        displayName: getOrgName(),
        paymentRequest: {
          total: {
            label: "Total",
            amount: roundAmountNoCurrency(amount),
          },
          requiredBillingContactFields: [
            "name",
            "email",
            "phone",
            "postalAddress",
          ],
          requiredShippingContactFields: ["name", "postalAddress"],
        },
      };
    }

    return config;
  } else {
    return {};
  }
};

const getNMIConfig = () => {
  const cardGateway = getPaymentGatewayCard();

  if (cardGateway?.vendor_name === "nmi") {
    let config = {};

    if (cardGateway.google_pay) {
      config.googlePay = {
        selector: ".googlePayButton",
        shippingAddressRequired: true,
        billingAddressRequired: true,
        billingAddressParameters: {
          phoneNumberRequired: true,
          format: "FULL",
        },
        emailRequired: true,
      };
    }

    if (cardGateway.apple_pay) {
      config.applePay = {
        selector: ".applePayButton",
        requiredBillingContactFields: ["name", "postalAddress"],
        requiredShippingContactFields: ["name", "postalAddress"],
        contactFields: ["phone", "email"],
        contactFieldsMappedTo: "shipping",
        totalLabel: "Total",
      };
    }

    return config;
  } else {
    return {};
  }
};

const formatInvoiceDiscounts = (inv) => {
  let discounts = [];

  // Add Invoice Discounts
  inv.invoiceDiscounts?.forEach((dis) => {
    discounts.push(
      `${dis.discount?.name || dis.metadata?.name}: ${roundAmount(dis.amount)}`
    );
  });

  // Custom Discount
  if (inv.discount_value) {
    discounts.push(`Discount: ${roundAmount(inv.discount_value)}`);
  }

  // Line Items Discount
  let liDisc = 0.0;
  inv.lineItems.forEach((line) => (liDisc += line.discount_amount));
  if (liDisc > 0) {
    discounts.push(`Line Item Discounts: ${roundAmount(liDisc)}`);
  }

  return discounts.join(", ");
};

const formatInvoiceTaxes = (inv) => {
  let taxes = [];

  // Add Invoice Taxes
  inv.invoiceTaxes?.forEach((tax) => {
    taxes.push(
      `${tax.taxRate?.name || tax.metadata?.name}: ${roundAmount(tax.amount)}`
    );
  });

  // Custom Tax
  if (inv.tax_value) {
    taxes.push(`Tax: ${roundAmount(inv.tax_value)}`);
  }

  // Line Items Tax
  let liTax = 0.0;
  inv.lineItems.forEach((line) => (liTax += line.tax_amount));
  if (liTax > 0) {
    taxes.push(`Line Item Taxes: ${roundAmount(liTax)}`);
  }

  return taxes.join(", ");
};

export {
  isCryptoGatewaySetup,
  roundAmountNoCurrency,
  roundAmount,
  isAchCardPaymentGatewaySetup,
  isPaymentGatewaySetup,
  getOfflinePaymentMeta,
  getIsAccountExist,
  getPaymentGatewayCard,
  getFeeInfo,
  getLogoURL,
  getOrgName,
  getAccountName,
  getBrandIconURL,
  getCurrencySigntInfo,
  getUserFullName,
  setPageTitle,
  isCardPaymentGatewaySetup,
  isPayPalEnabled,
  isPayPalVenmoActive,
  isPayPalCardActive,
  isPayPalPayLaterActive,
  getPayPalMerchantId,
  setPayPalOrder,
  getPayPalOrder,
  getPayPalMerchant,
  isAchPaymentGatewaySetup,
  getUserCompany,
  isBetweenRange,
  isModifyBilling,
  isShowItemDescription,
  isGooglePayEnabled,
  isApplePayEnabled,
  isApplePayDomainVerified,
  getCurrencySign,
  getBraintreeConfig,
  getNMIConfig,
  getUserEmail,
  formatInvoiceDiscounts,
  formatInvoiceTaxes,
};
