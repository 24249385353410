// @flow
import { DonationActionTypes } from "./constants";

const INIT_STATE = {
  loading: false,
  data: {},
};

type DonationAction = {
  type: string,
  payload: { actionType?: string, data?: any, error?: string },
};
type State = { data?: {} | null, loading?: boolean, value?: boolean };

const Donation = (state: State = INIT_STATE, action: DonationAction): any => {
  switch (action.type) {
    case DonationActionTypes.API_RESPONSE_SUCCESS:
      switch (action.payload.actionType) {
        case DonationActionTypes.DONATIONS: {
          return {
            ...state,
            data: action.payload.data,
            loading: false,
            error: false,
          };
        }
        case DonationActionTypes.DONATIONS_ALL: {
          return {
            ...state,
            data: action.payload.data,
            loading: false,
            error: false,
          };
        }
        case DonationActionTypes.DONATIONS_FETCH: {
          return {
            ...state,
            donation_data: action.payload.data,
            loading: false,
            donation_fetch_error: false,
          };
        }

        case DonationActionTypes.COUNT_PAYMENT_LINK: {
          return {
            ...state,
            count_payment_link_data: action.payload.data,
            loading: false,
            count_payment_link_error: false,
          };
        }

        case DonationActionTypes.COUNT_DONATION: {
          return {
            ...state,
            count_donation_data: action.payload.data,
            loading: false,
            count_donation_error: false,
          };
        }

        case DonationActionTypes.CANCEL_PAYMENT_LINK: {
          return {
            ...state,
            cancel_payment_link_data: action.payload.data,
            loading: false,
            error: false,
          };
        }

        case DonationActionTypes.CANCEL_DONATION: {
          return {
            ...state,
            cancel_donation_data: action.payload.data,
            loading: false,
            error: false,
          };
        }

        case DonationActionTypes.ACCEPT_DONATION: {
          return {
            ...state,
            donation_accept_data: action.payload.data,
            loading: false,
            error: false,
          };
        }
        case DonationActionTypes.SUBSCRIPTION_ALL: {
          return {
            ...state,
            subscriptioin_data: action.payload.data,
            loading: false,
            error: false,
          };
        }
        case DonationActionTypes.PAYMENT_LINK: {
          return {
            ...state,
            payment_link_data: action.payload.data,
            loading: false,
            payment_link_fetch_error: false,
          };
        }
        case DonationActionTypes.PAY_PAYMENT_LINK: {
          return {
            ...state,
            pay_payment_link_data: action.payload.data,
            loading: false,
            error: false,
          };
        }

        default:
          return { ...state };
      }

    case DonationActionTypes.API_RESPONSE_ERROR:
      switch (action.payload.actionType) {
        case DonationActionTypes.DONATIONS: {
          return {
            ...state,
            error: action.payload.error,

            loading: false,
          };
        }

        case DonationActionTypes.DONATIONS_ALL: {
          return {
            ...state,
            error: action.payload.error,

            loading: false,
          };
        }

        case DonationActionTypes.DONATIONS_FETCH: {
          return {
            ...state,
            donation_fetch_error: action.payload.error,
            loading: false,
          };
        }

        case DonationActionTypes.COUNT_PAYMENT_LINK: {
          return {
            ...state,
            count_payment_link_error: action.payload.error,
            loading: false,
          };
        }

        case DonationActionTypes.COUNT_DONATION: {
          return {
            ...state,
            count_donation_error: action.payload.error,
            loading: false,
          };
        }

        case DonationActionTypes.ACCEPT_DONATION: {
          return {
            ...state,
            error: action.payload.error,
            loading: false,
          };
        }

        case DonationActionTypes.SUBSCRIPTION_ALL: {
          return {
            ...state,
            error: action.payload.error,

            loading: false,
          };
        }

        case DonationActionTypes.PAYMENT_LINK: {
          return {
            ...state,
            payment_link_fetch_error: action.payload.error,
            loading: false,
          };
        }

        case DonationActionTypes.CANCEL_PAYMENT_LINK: {
          return {
            ...state,
            loading: false,
            error: action.payload.error,
          };
        }

        case DonationActionTypes.CANCEL_DONATION: {
          return {
            ...state,
            loading: false,
            error: action.payload.error,
          };
        }

        case DonationActionTypes.PAY_PAYMENT_LINK: {
          return {
            ...state,
            error: action.payload.error,

            loading: false,
          };
        }

        default:
          return { ...state };
      }

    case DonationActionTypes.DONATIONS:
      return { ...state, loading: true, userLoggedIn: false };
    case DonationActionTypes.DONATIONS_ALL:
      return { ...state, loading: true, account: null };
    case DonationActionTypes.DONATIONS_FETCH:
      return { ...state, loading: true, donation_fetch_error: false };

    case DonationActionTypes.COUNT_PAYMENT_LINK:
      return { ...state, loading: true, count_payment_link_error: false };

    case DonationActionTypes.COUNT_DONATION:
      return { ...state, loading: true, count_donation_error: false };

    case DonationActionTypes.SUBSCRIPTION_ALL:
      return { ...state, loading: true, subscriptioin_data: null };
    case DonationActionTypes.PAYMENT_LINK:
      return {
        ...state,
        loading: true,
        payment_link_data: null,
        payment_link_fetch_error: null,
      };
    case DonationActionTypes.PAY_PAYMENT_LINK:
      return { ...state, loading: true, payment_link_data: null };
    case DonationActionTypes.ACCEPT_DONATION:
      return { ...state, loading: true };

    case DonationActionTypes.RESET:
      return {
        ...state,
        loading: false,
        error: false,
        data: null,
        donation_fetch_error: false,
        subscriptioin_data: null,
        donation_accept_data: null,
        payment_link_data: null,
        pay_payment_link_data: null,
        payment_link_fetch_error: false,

        donation_data: null,
      };
    default:
      return { ...state };
  }
};

export default Donation;
