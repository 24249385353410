// @flow
import { combineReducers } from "redux";

import Auth from "./auth/reducers";
import Invoice from "./invoice/reducers";
import Estimate from "./estimate/reducers";
import Layout from "./layout/reducers";
import Payment from "./payment/reducers";
import Donation from "./donation/reducers";

export default (combineReducers({
  Auth,
  Invoice,
  Layout,
  Estimate,
  Payment,
  Donation,
}): any);
