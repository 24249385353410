// @flow
import React, { useEffect } from "react";
import Routes from "./routes/Routes";
//import { config } from 'dotenv-cra';
// setup fake backend

import { useSelector } from "react-redux";

// Import Utils
import utils from "./helpers/utils";

// Themes
import "./assets/scss/Creative.scss";
import "./index.css";
import { APICore } from "./helpers/api/apiCore";

const api = new APICore();

/**
 * Main app component
 */

const App = (props) => {
  // dotenvCra.config();
  const { accounDatat } = useSelector((state) => ({
    accounDatat: state.Auth.account,
  }));

  // Apply Color Scheme
  useEffect(() => {
    let account = api.getAccount();
    if (account && account?.preferences?.accent_color) {
      utils.changeTheme(account.preferences.accent_color);
    } else if (account && account?.preferences?.brand_color) {
      utils.changeTheme(account?.partner?.brand_color);
    }
    if (account && account?.partner?.id) {
      if (account?.partner?.brand_icon) {
        utils.changeFavIcon(account?.partner?.brand_icon);
      } else if (account?.partner?.brand_logo) {
        utils.changeFavIcon(account?.partner?.brand_logo);
      }
    } else {
      utils.setDefaultFavIcon();
    }
  }, [accounDatat]);

  return <Routes> </Routes>;
};

export default App;
